<template>
  <div class="cartTotal__promocode promocode">
    <p class="promocode__title">Использовать промокод</p>

    <form class="promocode__form">
      <input type="text" class="promocode__input" placeholder="Промокод" v-model=value
             :disabled="applied">
      <button v-if="!applied" class="promocode__button" type="button" @click="apply"><i
          class="fa-solid fa-angles-right"></i></button>
      <button v-else class="promocode__button" type="button" @click="cancel"><i class="fa-solid fa-circle-xmark"></i>
      </button>
    </form>
    <p class="alert alert-success" v-if="success !== ''">{{ success }}</p>
    <p class="alert alert-warning" v-if="warning !== ''">{{ warning }}</p>
    <p class="alert alert-danger" v-if="danger !== ''">{{ danger }}</p>
  </div>
</template>

<script>
import request from "@/request";

export default {
  name: "dtl-Promocode",
  props: ['coupon'],
  created() {
    if (this.coupon !== '' && this.coupon !== undefined) {
      this.value = this.coupon
      this.applied = true
      this.success = 'Промо-код применен'
    }
  },
  data() {
    return {
      value: '',
      applied: false,
      success: '',
      danger: '',
      warning: ''
    }
  },
  methods: {
    async apply() {
      this.success = ''
      this.danger = ''
      this.warning = ''
      const formData = new FormData();
      formData.append('mspc_coupon', this.value)
      const response = await request.post('order/cart/set-coupon', formData)
      if (response.data.success === true) {
        this.applied = true
        this.value = response.data.mspc.coupon
        if (response.data.mspc.success) {
          this.success = response.data.mspc.success
        }
        if (response.data.mspc.warning) {
          this.warning = response.data.mspc.warning
        }

        this.$emit('updated')
      }
      if (response.data.success === false) {
        if (response.data.mspc.error) {
          this.danger = response.data.mspc.error
        }
        if (response.data.mspc.warning) {
          this.warning = response.data.mspc.warning
        }
      }
    },
    async cancel() {
      this.success = ''
      this.danger = ''
      this.warning = ''
      const formData = new FormData();
      const response = await request.post('order/cart/unset-coupon', formData)
      if (response.data.success === true) {
        this.applied = false
        this.value = ''
        if (response.data.mspc.success) {
          this.success = response.data.mspc.success
        }
        if (response.data.mspc.warning) {
          this.warning = response.data.mspc.warning
        }
        this.$emit('updated')
      }

    }
  }
}
</script>