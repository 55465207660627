<template>
  <div v-if="defaultImage === ''">
    <VueFileAgent
        ref="vueFileAgent"
        :theme="'grid'"
        :multiple="false"
        :deletable="true"
        :accept="'image/*'"
        :maxSize="'20MB'"
        :helpText="'Загрузите фото'"
        :errorText="{
            type: 'Разрешены только изображения',
            size: 'Не больше 20 МB',
          }"
        @select="filesSelected($event)"
        @beforedelete="onBeforeDelete($event)"
        @delete="fileDeleted($event)"
        v-model="fileRecords"
    />
  </div>
  <div class="d-flex flex-column" v-else>
    <img :src="defaultImage" alt="">
    <a href="#" class="officePage__cardButton" @click="changePhoto">Изменить фото</a>
  </div>

</template>

<script>
export default {
  name: "ProfilePhoto",
  props: ['user_id', 'startUpload', 'photo'],
  watch: {
    startUpload() {
      if (this.startUpload === true) {
        this.upload()
      }
    },
  },
  created() {
    if (this.photo !== '') {
      this.defaultImage = this.photo
    }

    this.setUploadUrl()
  },
  data() {
    return {
      baseurl: 'https://dtlshop.ru',
      defaultImage: '',
      fileRecords: [],
      fileRecordsForUpload: [],
      uploadUrl: '',
      uploadHeaders: {},
    }
  },
  methods: {
    setUploadUrl() {
      if (location.hostname === 'localhost') {
        this.baseurl = 'https://dtlshop.ru'
      } else {
        this.baseurl = 'https://' + location.hostname
      }
      this.uploadUrl = this.baseurl + '/api/v1/office/profile/upload/' + this.user_id
    },
    filesSelected: function (fileRecordsNewlySelected) {
      const validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error);
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
      this.$emit('select', this.fileRecordsForUpload)
    },
    onBeforeDelete: function (fileRecord) {
      let i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        // queued file, not yet uploaded. Just remove from the arrays
        this.fileRecordsForUpload.splice(i, 1);
        let k = this.fileRecords.indexOf(fileRecord);
        if (k !== -1) this.fileRecords.splice(k, 1);
      } else {
        if (confirm('Удалить изображение?')) {
          this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
        }
      }
    },
    fileDeleted: function (fileRecord) {
      let i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      } else {
        this.deleteUploadedFile(fileRecord);
      }
    },
    upload() {
      if (this.fileRecordsForUpload.length > 0) {
        const response = this.$refs.vueFileAgent.upload(this.uploadUrl, this.uploadHeaders, this.fileRecordsForUpload)
        response.then((response) => {
          if (response.length > 0) {
            response.forEach(resp => {
              const data = resp.data;
              if (data.success === true) {
                const files = data.files;
                this.uploadedFiles = [];
                if (files && files.length > 0) {
                  files.forEach(file => {
                    this.uploadedFiles.push(file)
                  })
                }
              }
              if (data.success === false) {
                if (data.message) {
                  window.miniShop2.Message.error(data.message)
                }
              }
            })
            this.fileRecordsForUpload = []
            this.$emit('uploaded')
          }
        })
      }
    },
    changePhoto(event) {
      event.preventDefault();
      this.defaultImage = ''
    },
  }
}
</script>
<style>
@import '~vue-file-agent/dist/vue-file-agent.css';
</style>