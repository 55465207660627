<template>
  <form class="profilePage__form">
    <div class="loader" v-if="loading">
      <span class="loader__indicator"></span>
    </div>


    <div class="form__section">
      <div class="form__group form__group--append">
        <label for="password" class="form__label">Введите текущий пароль</label>
        <input :type="inputType" id="password" class="form__input form__control" v-model="password">
        <button type="button" class="form__append"
                @click="inputType === 'password' ? inputType = 'text' : inputType = 'password'"
        ><i
            class="fa-regular"
            :class="{'fa-eye': inputType === 'password', 'fa-eye-slash': inputType === 'text'}"></i>
        </button>
      </div>

      <div class="alert alert-danger mb-4" v-if="passwordError.length > 0">
        <p class="mt-0 mb-0">{{ passwordError }}</p>
      </div>

      <div class="form__group form__group--append">
        <label for="new_password" class="form__label">Введите новый пароль</label>
        <input :type="inputType2" id="new_password" class="form__input form__control" v-model="newPassword">
        <button type="button" class="form__append"
                @click="inputType2 === 'password' ? inputType2 = 'text' : inputType2 = 'password'"
        ><i
            class="fa-regular"
            :class="{'fa-eye': inputType2 === 'password', 'fa-eye-slash': inputType2 === 'text'}"></i>
        </button>
      </div>


      <div class="alert alert-danger mb-4" v-if="newPasswordError.length > 0">
        <p class="mt-0 mb-0">{{ newPasswordError }}</p>
      </div>

      <template v-if="showCode">
        <div class="form__otp">
          <OtpInput
              ref="otpInput"
              input-classes="otp-input"
              separator="-"
              :num-inputs="4"
              :should-auto-focus="true"
              :is-input-num="true"
              @on-complete="handleOnComplete"
              name="otp"
          ></OtpInput>
        </div>

        <div class="alert alert-warning mb-4" role="alert">
          <p>Мы отправили СМС с проверочным кодом на ваш номер телефона
            После ввода кода, пароль будет изменен.</p>
        </div>
      </template>


    </div>
    <button type="button" class="button button--yellow" @click="submit">Изменить пароль</button>
  </form>
</template>

<script>
import request from "@/request";
import OtpInput from "@bachdgvn/vue-otp-input";

export default {
  name: "PasswordForm",
  components: {
    OtpInput,
  },
  created() {
    if (this.$parent.user_id !== undefined) {
      this.user_id = parseInt(this.$parent.user_id)
    }
  },
  data() {
    return {
      loading: false,
      user: {},
      userReady: false,
      inputType: 'password',
      inputType2: 'password',
      password: '',
      newPassword: '',
      passwordError: '',
      newPasswordError: '',
      showCode: false,
      code: ''
    }
  },
  methods: {
    async submit() {
      const isChecked = this.checkFields()
      if (!isChecked) {
        return false
      }
      const formData = new FormData();
      formData.append('password', this.password)
      formData.append('new_password', this.newPassword)
      if (this.code !== '') {
        formData.append('code', this.code)
      }

      const response = await request.post('office/profile/' + this.user_id + '/update-password', formData)
      if (response.data.success === true) {
        this.loading = false

        if (response.data.listen_code !== undefined) {
          this.showCode = true
        }

        if (response.data.code_verified !== undefined) {
          this.showCode = false
          this.password = ''
          this.newPassword = ''
          window.miniShop2.Message.success('Пароль успешно изменен')
        }
      }

      if (response.data.success === false) {
        this.loading = false

        if (response.data.message !== undefined && response.data.message !== '') {
          window.miniShop2.Message.error(response.data.message)
        }
      }

    },
    checkFields() {
      this.passwordError = ''
      this.newPasswordError = ''

      const errors = []
      if (this.password.length < 8) {
        errors.push('password')
        console.log(this.password.length);
        this.passwordError = 'Длина пароля должна быть не менее 8 знаков'
      }
      if (this.newPassword.length < 8) {
        errors.push('newPassword')
        this.newPasswordError = 'Длина нового пароля должна быть не менее 8 знаков'
      }

      if (this.password === this.newPassword) {
        errors.push('newPassword')
        this.newPasswordError = 'Новый пароль должен отличаться от текущего'
      }

      return errors.length <= 0
    },
    handleOnComplete(value) {
      this.code = value
      this.submit()
    },
  }
}
</script>

<style>

</style>