<template>
<div>
  <div class="modal__container">
    <button class="modal__close" aria-label="Close modal" data-micromodal-close>
      <svg style="height: 16px; width: 16px;">
        <use xlink:href="assets/template2/images/symbol/sprite.svg#close"></use>
      </svg>
    </button>

    <div class="modal__header">
      <p class="modal__title" id="modal-opt-title">
        Запросить<br>оптовый прайс
      </p>
    </div>

    <div class="modal__content" id="modal-opt-content">
      <form class="modal__form">
        <input type="text" autofocus style="display: none">
        <div class="form__group">
          <input
              type="text"
              placeholder="Имя"
              required="required"
              class="form__input modal__input"
              v-model="name"
              :class="{'form__input--invalid' : errors.name !== ''}"
          >
        </div>

        <div class="form__group">
          <input
              type="text"
              placeholder="Фамилия"
              required="required"
              class="form__input modal__input"
              v-model="lastname"
              :class="{'form__input--invalid' : errors.lastname !== ''}"
          >
        </div>

        <div class="form__group">
          <input
              type="text"
              placeholder="Компания"
              required="required"
              class="form__input modal__input"
              v-model="company"
              :class="{'form__input--invalid' : errors.company !== ''}"
          >
        </div>
        <div class="form__group">
          <vue-tel-input
              :defaultCountry="'ru'"
              :mode="'international'"
              :validCharactersOnly="true"
              v-model="telInputValue"
              :dropdownOptions="telDropdownOptions"
              :inputOptions="telInputOptions"
              :styleClasses="telInputStyleClasses"
              @validate="telInputValidate"
              @input="telInputMask"
          />
          <div
              v-if="phoneValidationFeedBack !== null"
              class="invalid-feedback">
            {{ phoneValidationFeedBack }}
          </div>
        </div>
      </form>
    </div>

    <div class="modal__footer">

      <button class="button button--red modal__button"
              style="padding: 15px;"
              :disabled="disabled"
              @click="send"
      >Отправить заявку</button>

      <button type="button" class="button button--gray modal__button" data-micromodal-close>Отмена</button>
    </div>
  </div>
  <div class="loader" v-if="loading">
    <span class="loader__indicator"></span>
  </div>
</div>
</template>

<script>
import {VueTelInput} from 'vue-tel-input'
import request from "@/request";

export default {
  name: "ModalOpt",
  components: {
    VueTelInput,
  },
  data() {
    return {
      phone: '',
      name: '',
      lastname: '',
      company: '',
      telInputValue: '',
      telInputStyleClasses: ['form__input'],
      telDropdownOptions: {
        showDialCodeInSelection: false,
        showFlags: false,
        showDialCodeInList: false,
        showSearchBox: false,
        disabled: true
      },
      telInputOptions: {
        placeholder: '+7-___-___-__-__',
        required: true,
        name: 'phone'
      },
      phoneValidationFeedBack: null,
      disabled: true,
      loading: false,
      errors: {
        name: '',
        lastname: '',
        company: '',
        phone: ''
      }
    }
  },
  methods: {
    telInputValidate(input) {
      this.disabled = !input.valid
      this.telInputStyleClasses = ['form__input']
      if (input.valid === true) {
        this.phone = input.number
        this.telInputStyleClasses.push('form__input--valid')
      }
    },
    telInputMask(value) {
      if (value === '87') {
        this.telInputValue = '+77'
      }
      if (value === '89') {
        this.telInputValue = '+79'
      }
      if (value === '77') {
        this.telInputValue = '+77'
      }
      if (value === '79') {
        this.telInputValue = '+79'
      }
    },
    async send() {
      this.loading = true
      this.disabled = true
      this.phoneValidationFeedBack = null
      this.telInputStyleClasses = ['form__input']
      this.errors = {
        name: '',
        lastname: '',
        company: '',
        phone: ''
      }

      const formdata = new FormData()
      formdata.append('phone', this.phone)
      formdata.append('name', this.name)
      formdata.append('lastname', this.lastname)
      formdata.append('company', this.company)
      const response = await request.post('opt', formdata);

      if (response.data.success === false) {
        this.loading = false
        this.disabled = false

        if (response.data.errors && Object.keys(response.data.errors).length > 0) {
          Object.assign(this.errors, response.data.errors)
        }

        if (response.data.message !== '') {
          window.miniShop2.Message.error(response.data.message)
        }
      }

      if (response.data.success === true) {
        this.loading = false
        this.disabled = false
        this.name = ''
        this.lastname = ''
        this.phone = ''
        this.telInputValue = ''
        this.company = ''
        window.MicroModal.close('modal-opt')
        window.miniShop2.Message.success('Заявка отправлена')


        const crhosts = ['devcr.dtlshop.ru', 'chemicalrussian.ru'];
        if (!crhosts.includes(location.host)) {
          window.ym(44716432, 'reachGoal', 'Get_an_offer')
        }
      }
    },
  },
}
</script>