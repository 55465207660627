<template>
  <div class="productOneClick">
    <button class="button button--black" type="button"
            @click="showModal"
            style="margin: auto; width: 234px;"
    >
      Быстрый заказ
    </button>

    <div class="modal micromodal-slide" id="modal-cart-one-click" aria-hidden="true">
      <div class="modal__overlay" tabindex="-1" data-micromodal-close>
        <div role="dialog" aria-modal="true" aria-labelledby="modal-cart-one-click-title">

          <div class="modal__container">
            <button aria-label="Close modal" data-micromodal-close="" class="modal__close">
              <svg style="height: 18px; width: 18px;"><use xlink:href="/assets/template2/images/symbol/sprite.svg#close"></use></svg>
            </button>
            <div class="modal__header">
              <p id="modal-location-title" class="modal__title">Быстрый заказ</p>
            </div>
            <div id="modal-location-content" class="modal__content">

              <form class="productOneClick__form">
                <input type="text" autofocus="autofocus" style="display: none;">
                <div class="form__group">
                  <input type="text" placeholder="Имя" required="required" class="modal__input" v-model="name" style="padding: 10px">
                </div>


                <vue-tel-input
                    :defaultCountry="'ru'"
                    :mode="'international'"
                    :validCharactersOnly="true"
                    v-model="telInputValue"
                    :dropdownOptions="telDropdownOptions"
                    :inputOptions="telInputOptions"
                    :styleClasses="telInputStyleClasses"
                    @validate="telInputValidate"
                    @input="telInputMask"
                />
                <div
                    v-if="phoneValidationFeedBack !== null"
                    class="invalid-feedback">
                  {{ phoneValidationFeedBack }}
                </div>
              </form>
             </div>
            <div class="modal__footer">
              <button class="button button--red modal__button" @click="send">Заказать</button>
              <button type="button" data-micromodal-close="" class="button button--gray modal__button">Отмена</button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueTelInput } from 'vue-tel-input'
import request from '@/request'

export default {
  name: 'CartOneClick',
  components: {
    VueTelInput,
  },
  props: ['order_id'],
  data () {
    return {
      name: '',
      phone: '',
      telInputValue: '',
      telInputStyleClasses: ['form__input'],
      telDropdownOptions: {
        showDialCodeInSelection: false,
        showFlags: false,
        showDialCodeInList: false,
        showSearchBox: false,
        disabled: true
      },
      telInputOptions: {
        placeholder: '+7-___-___-__-__',
        required: true,
        name: 'phone',
      },
      phoneValidationFeedBack: null,
      disabled: true,
      loading: false,
    }
  },
  methods: {
    telInputValidate (input) {
      this.disabled = !input.valid
      this.telInputStyleClasses = ['form__input']
      if (input.valid === true) {
        this.phone = input.number
        this.telInputStyleClasses.push('form__input--valid')
      }
    },
    telInputMask (value) {
      if (value === '87') {
        this.telInputValue = '+77'
      }
      if (value === '89') {
        this.telInputValue = '+79'
      }
      if (value === '77') {
        this.telInputValue = '+77'
      }
      if (value === '79') {
        this.telInputValue = '+79'
      }
    },
    async send () {
      this.loading = true
      this.disabled = true
      this.phoneValidationFeedBack = null
      this.telInputStyleClasses = ['form__input']

      const formdata = new FormData()
      formdata.append('phone', this.phone)
      formdata.append('name', this.name)
      formdata.append('order_id', this.order_id)
      const response = await request.post('order/cart/one-click', formdata)

      if (response.data.success === false) {
        this.loading = false
        this.disabled = false

        if (response.data.message !== '') {
          this.telInputStyleClasses.push('form__input--invalid')
          this.phoneValidationFeedBack = response.data.message
        }
      }

      if (response.data.success === true) {
        this.disabled = false
        this.phone = ''
        this.telInputValue = ''

        this.setEcommerce(response.data)
        window.MicroModal.close('modal-cart-one-click')
        window.miniShop2.Message.success('Заявка отправлена')

        if (response.data.redirect !== undefined) {
          setTimeout(() => {
            window.location.href = response.data.redirect
          }, 3000)
        } else {
          this.loading = false
        }
      }
    },
    setEcommerce (response) {
      const dataLayerProducts = []
      response.products.forEach(product => {
        dataLayerProducts.push({
          id: `${product.properties.article}`,
          price: product.properties.price,
          quantity: product.properties.count,
          name: `${product.properties.name}`,
          brand: `${product.properties.brand}`,
          category: `${product.properties.category}`
        })
      })

      window.dataLayer.push({
        'ecommerce': {
          'currencyCode': 'RUB',
          'purchase': {
            'actionField': {
              'id': `${response.order.num}`,
              'affiliation': `${location.host}`,
              'revenue': response.order.cost,
              'tax': '0',
              'shipping': '0',
            },
            'products': dataLayerProducts
          }
        },
        'event': 'ee_event',
        'ee_event_category': 'Enhanced Ecommerce',
        'ee_event_action': 'Purchase',
        'ee_event_non-interaction': 'False',
      })

      const crhosts = ['devcr.dtlshop.ru', 'chemicalrussian.ru'];
      if (!crhosts.includes(location.host)) {
        window.ym(44716432, 'reachGoal', 'buy_in_1_click_cart')
      }

    },
    showModal() {
      window.MicroModal.show('modal-cart-one-click')
    }
  },
}
</script>