<template>
  <div>
    <div v-if="view === 'modal'">
      <div class="modal__content" id="modal-auth2-content">
        <form class="modal__form" @submit.prevent>
          <div class="form__group">
            <vue-tel-input
                :defaultCountry="'ru'"
                :mode="'international'"
                :validCharactersOnly="true"
                v-model="telInputValue"
                :dropdownOptions="telDropdownOptions"
                :inputOptions="telInputOptions"
                :styleClasses="telInputStyleClasses"
                @validate="telInputValidate"
                @input="telInputMask"
            />
            <div
                v-if="phoneValidationFeedBack !== null"
                class="invalid-feedback">
              {{ phoneValidationFeedBack }}
            </div>
          </div>
        </form>

        <p class="modal__text">Вам поступит СМС с проверочным кодом.</p>
        <p class="modal__text">Введите 4 цифры кода.</p>

        <a href="#" class="modal__link"
           @click.prevent="$emit('changeInput', {input:'PasswordInput'})"
        >Войти с помощью пароля</a>
      </div>

      <div class="modal__footer">

        <button class="button button--red modal__button" type="button"
                :disabled="disabled"
                @click="recaptcha"
        > Отправить SMS
        </button>

        <button type="button" class="button button--gray modal__button" data-dismiss="modal" data-micromodal-close>Отмена</button>
      </div>

      <div class="loader" v-if="loading">
        <span class="loader__indicator"></span>
      </div>
    </div>

    <div v-if="view === 'page'">
      <div class="form-group">
        <label>По номеру телефона <span class="required">*</span></label>
        <vue-tel-input
            :defaultCountry="'ru'"
            :mode="'international'"
            :validCharactersOnly="true"
            v-model="telInputValue"
            :dropdownOptions="telDropdownOptions"
            :inputOptions="telInputOptions"
            :styleClasses="telInputStyleClasses"
            @validate="telInputValidate"
            @input="telInputMask"
        ></vue-tel-input>
        <div
            v-if="phoneValidationFeedBack !== null"
            class="invalid-feedback">
          {{ phoneValidationFeedBack }}
        </div>

        <div class="alert alert-warning my-4" role="alert">
          Вам поступит СМС с проверочным кодом. <br>Введите 4 цифры кода.
        </div>
      </div>

      <div class="d-flex justify-align-center justify-content-between">
        <a href="#" class="btn btn-link"
           @click.prevent="$emit('changeInput', {input:'PasswordInput'})"
        >Войти с помощью пароля</a>



        <button
            class="btn btn-success d-flex align-items-center"
            :disabled="disabled"
            @click="recaptcha"
        >
        <span v-if="loading" class="spinner-border spinner-border-sm spinner-btn mr-2" role="status"
              aria-hidden="true"></span>
          Получить код
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {VueTelInput} from 'vue-tel-input'
import request from "@/request";


export default {
  name: "PhoneInput",
  components: {
    VueTelInput,
  },
  props: ['clientPhone', 'view'],
  created() {
    this.telInputValue = this.clientPhone
  },
  data() {
    return {
      phone: '',
      telInputValue: '',
      telInputStyleClasses: ['form__input'],
      telDropdownOptions: {
        showDialCodeInSelection: false,
        showFlags: false,
        showDialCodeInList: false,
        showSearchBox: false,
        disabled: true
      },
      telInputOptions: {
        placeholder: '+7-___-___-__-__',
        required: true,
        name: 'phone'
      },
      phoneValidationFeedBack: null,
      disabled: true,
      loading: false,
    }
  },
  methods: {
    telInputValidate(input) {
      this.disabled = !input.valid
      this.telInputStyleClasses = ['form__input']
      if (input.valid === true) {
        this.phone = input.number
        this.telInputStyleClasses.push('form__input--valid')
        this.getCodeDisabled = false
      }
    },
    telInputMask(value) {
      if (value === '87') {
        this.telInputValue = '+77'
      }
      if (value === '89') {
        this.telInputValue = '+79'
      }
      if (value === '77') {
        this.telInputValue = '+77'
      }
      if (value === '79') {
        this.telInputValue = '+79'
      }
    },
    async recaptcha() {
      await this.$recaptchaLoaded()
      const token = await this.$recaptcha('send')
      await this.send(token);
    },
    async send(token) {
      this.loading = true
      this.disabled = true
      this.phoneValidationFeedBack = null
      this.telInputStyleClasses = ['form__input']

      const formdata = new FormData()
      formdata.append('phone', this.phone)
      formdata.append('token', token)

      const response = await request.post('auth/send', formdata);

      if (response.data.success === false) {
        this.loading = false
        this.disabled = false

        if (response.data.message !== '') {
          this.telInputStyleClasses.push('form__input--invalid')
          this.phoneValidationFeedBack = response.data.message
        }
      }

      if (response.data.success === true) {
        this.$emit('changeInput', {
          phone: this.phone,
          input: 'SmsInput',
          user_exists: response.data.user_exists,
        })
      }
    },
  },
}
</script>