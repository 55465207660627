<template>
  <div class="ecModal">
    <div class="modal__container">
      <button class="modal__close" aria-label="Close modal" data-micromodal-close
              style="font-size: 23px; color: rgba(174, 174, 174, 1)">
        <i class="fa-solid fa-xmark"></i>
      </button>

      <div class="modal__header">
        <h2 class="modal__title" id="modal-comm-title">
          Напишите свой отзыв
        </h2>
      </div>

      <div class="modal__content" id="modal-comm-content">
        <form class="modal__form">
          <p class="modal__text">Спасибо, что делитесь своим мнением с другими пользователями!</p>

          <div class="d-flex align-items-start flex-wrap">
            <div class="w-50">
              <div class="form__group">
                <input type="text" v-model="name" class="modal__input" placeholder="Ваше Имя"
                       :class="{'form__input--invalid' : errors.name !== ''}"
                >
              </div>
            </div>
            <div class="w-50">
              <div class="ecModal__rating">
                <p class="ecModal__ratingTitle">Оценка товара</p>
                <div class="ecModal__ratingContent">
                  <div v-if="stars === 0" class="reviews__stars">
                    <i :class="{
                      'fa-star': true,
                       'fa-solid': highlight > 0,
                       'fa-regular': highlight === 0,
                    }"
                       @mouseover="highlightStar(1)"
                       @mouseleave="highlightStar(0)"
                       @click="setStars(1)"
                    ></i>
                    <i :class="{
                      'fa-star': true,
                       'fa-solid': highlight > 1,
                       'fa-regular': highlight <= 1,
                    }"
                       @mouseover="highlightStar(2)"
                       @mouseleave="highlightStar(0)"
                       @click="setStars(2)"
                    ></i>
                    <i :class="{
                      'fa-star': true,
                       'fa-solid': highlight > 2,
                       'fa-regular': highlight <= 2,
                    }"
                       @mouseover="highlightStar(3)"
                       @mouseleave="highlightStar(0)"
                       @click="setStars(3)"
                    ></i>
                    <i :class="{
                      'fa-star': true,
                       'fa-solid': highlight > 3,
                       'fa-regular': highlight <= 3,
                    }"
                       @mouseover="highlightStar(4)"
                       @mouseleave="highlightStar(0)"
                       @click="setStars(4)"
                    ></i>
                    <i :class="{
                      'fa-star': true,
                       'fa-solid': highlight > 4,
                       'fa-regular': highlight <= 4,
                    }"
                       @mouseover="highlightStar(5)"
                       @mouseleave="highlightStar(0)"
                       @click="setStars(5)"
                    ></i>
                  </div>

                  <div v-else class="reviews__stars">
                    <i :class="{
                      'fa-star': true,
                      'active': true,
                       'fa-solid': stars > 0,
                       'fa-regular': stars === 0,
                    }"
                       @click="setStars(1)"
                    ></i>
                    <i :class="{
                      'fa-star': true,
                      'active': true,
                       'fa-solid': stars > 1,
                       'fa-regular': stars <= 1,
                    }"
                       @click="setStars(2)"
                    ></i>
                    <i :class="{
                      'fa-star': true,
                      'active': true,
                       'fa-solid': stars > 2,
                       'fa-regular': stars <= 2,
                    }"
                       @click="setStars(3)"
                    ></i>
                    <i :class="{
                      'fa-star': true,
                      'active': true,
                       'fa-solid': stars > 3,
                       'fa-regular': stars <= 3,
                    }"
                       @click="setStars(4)"
                    ></i>
                    <i :class="{
                      'fa-star': true,
                      'active': true,
                       'fa-solid': stars > 4,
                       'fa-regular': stars <= 4,
                    }"
                       @click="setStars(5)"
                    ></i>
                  </div>

                  <span class="ecModal__ratingTotal" v-if="stars === 0">Нет оценки</span>
                  <span class="ecModal__ratingTotal" v-else>Оценка: {{ stars }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="form__group">
          <textarea v-model="message" class="modal__input" placeholder="Текст отзыва"
                    :class="{'form__input--invalid' : errors.message !== ''}"
          ></textarea>
          </div>
        </form>
      </div>

      <p class="ecModal__subtitle">Добавьте фото</p>
      <p class="ecModal__textGray">Чтобы загрузить фото в ваш отзыв, нажмите по кнопке ниже или просто перетащите фато в
        рамку. Размер изображения не более 20 Мб</p>
      <CommentImages
          :product="product"
          @select="commentImagesSelected($event)"
          @uploaded="uploaded"
          :startUpload="startUpload"
      />

      <p>Перед отправкой отзыва, пожалуйста, убедитесь, что он не нарушает правил модерации: не содержит оскорблений и
        ссылок на сторонние ресурсы.
        Ознакомьтесь с полными правилами модерации.</p>

      <div class="modal__footer flex-row">

        <button class="button button--yellow modal__button m-0"
                style="padding: 15px;"
                :disabled="disabled"
                @click="send"
        > Разместить отзыв
        </button>

        <button type="button" class="button button--outline modal__button" data-dismiss="modal" data-micromodal-close>
          Отмена
        </button>
      </div>

    </div>
    <div class="loader" v-if="loading">
      <span class="loader__indicator"></span>
    </div>
  </div>

</template>

<script>
import request from "@/request";
import CommentImages from "./CommentImages.vue";

export default {
  name: "dtl-Comment",
  components: {
    CommentImages
  },
  created() {
    if (this.$parent.product !== undefined) {
      this.product = parseInt(this.$parent.product)
    }
  },
  watch: {
    name() {
      this.disabled = !(this.name !== '' && this.message !== '' && this.stars !== 0)
    },
    message() {
      this.disabled = !(this.name !== '' && this.message !== '' && this.stars !== 0)
    },
    stars() {
      this.disabled = !(this.name !== '' && this.message !== '' && this.stars !== 0)
    },
  },
  data() {
    return {
      product: 0,
      message: '',
      name: '',
      highlight: 0,
      stars: 0,
      loading: false,
      errors: {
        name: '',
        message: '',
      },
      disabled: true,
      fileRecordsForUpload: [],
      startUpload: false,
    }
  },
  methods: {
    highlightStar(i) {
      this.highlight = i
    },
    setStars(i) {
      this.stars = i
    },
    send() {
      if (this.fileRecordsForUpload.length > 0) {
        this.startUpload = true
      } else {
        this.saveData()
      }
    },
    async saveData() {
      this.loading = true
      this.disabled = true

      const formdata = new FormData()
      formdata.append('product', this.product)
      formdata.append('stars', this.stars)
      formdata.append('name', this.name)
      formdata.append('message', this.message)
      const response = await request.post('comment', formdata);

      if (response.data.success === false) {
        this.loading = false
        this.disabled = false
        if (response.data.errors && Object.keys(response.data.errors).length > 0) {
          Object.assign(this.errors, response.data.errors)
        }
      }

      if (response.data.success === true) {
        this.loading = false
        this.disabled = false

        this.name = ''
        this.message = ''
        this.stars = 0
        this.highlight = 0

        window.MicroModal.close('modal-comm')
        window.miniShop2.Message.success('Комментарий отправлен на модерацию')
      }
    },
    commentImagesSelected(images) {
      this.fileRecordsForUpload = images
    },
    uploaded() {
      this.startUpload = false
      this.fileRecordsForUpload = []
      this.saveData()
    }
  }
}
</script>

<style lang="scss">
</style>