<template>
<div>
  <div class="modal__container">
    <button class="modal__close" aria-label="Close modal" data-micromodal-close>
      <svg style="height: 16px; width: 16px;">
        <use xlink:href="assets/template2/images/symbol/sprite.svg#close"></use>
      </svg>
    </button>

    <header class="modal__header">
      <h2 class="modal__title" id="modal-callback-title">
        Заказать<br>звонок менеджера
      </h2>
    </header>

    <div class="modal__content" id="modal-callback-content">
      <form class="modal__form">
        <div class="form__group">
          <vue-tel-input
              :defaultCountry="'ru'"
              :mode="'international'"
              :validCharactersOnly="true"
              v-model="telInputValue"
              :dropdownOptions="telDropdownOptions"
              :inputOptions="telInputOptions"
              :styleClasses="telInputStyleClasses"
              @validate="telInputValidate"
              @input="telInputMask"
          />
          <div
              v-if="phoneValidationFeedBack !== null"
              class="invalid-feedback">
            {{ phoneValidationFeedBack }}
          </div>
        </div>
      </form>

      <p class="modal__text">Для обратной связи вам достаточно указать номер телефона.</p>

    </div>

    <div class="modal__footer">

      <button class="button button--red modal__button"
              style="padding: 15px;"
              :disabled="disabled"
              @click="send"
      > Заказать обратный звонок</button>

      <button type="button" class="button button--gray modal__button" data-dismiss="modal" data-micromodal-close>Отмена</button>
    </div>
  </div>
  <div class="loader" v-if="loading">
    <span class="loader__indicator"></span>
  </div>
</div>
</template>

<script>
import {VueTelInput} from 'vue-tel-input'
import request from "@/request";

export default {
  name: "ModalCallback",
  components: {
    VueTelInput,
  },
  data() {
    return {
      phone: '',
      telInputValue: '',
      telInputStyleClasses: ['form__input'],
      telDropdownOptions: {
        showDialCodeInSelection: false,
        showFlags: false,
        showDialCodeInList: false,
        showSearchBox: false,
        disabled: true
      },
      telInputOptions: {
        placeholder: '+7-___-___-__-__',
        required: true,
        name: 'phone'
      },
      phoneValidationFeedBack: null,
      disabled: true,
      loading: false,
    }
  },
  methods: {
    telInputValidate(input) {
      this.disabled = !input.valid
      this.telInputStyleClasses = ['form__input']
      if (input.valid === true) {
        this.phone = input.number
        this.telInputStyleClasses.push('form__input--valid')
      }
    },
    telInputMask(value) {
      if (value === '87') {
        this.telInputValue = '+77'
      }
      if (value === '89') {
        this.telInputValue = '+79'
      }
      if (value === '77') {
        this.telInputValue = '+77'
      }
      if (value === '79') {
        this.telInputValue = '+79'
      }
    },
    async send() {
      this.loading = true
      this.disabled = true
      this.phoneValidationFeedBack = null
      this.telInputStyleClasses = ['form__input']

      const formdata = new FormData()
      formdata.append('phone', this.phone)
      const response = await request.post('callback', formdata);

      if (response.data.success === false) {
        this.loading = false
        this.disabled = false

        if (response.data.message !== '') {
          this.telInputStyleClasses.push('form__input--invalid')
          this.phoneValidationFeedBack = response.data.message
        }
      }

      if (response.data.success === true) {
        this.loading = false
        this.disabled = false

        window.MicroModal.close('modal-callback')
        window.miniShop2.Message.success('Заявка отправлена')


        const crhosts = ['devcr.dtlshop.ru', 'chemicalrussian.ru'];
        if (!crhosts.includes(location.host)) {
          window.ym(44716432, 'reachGoal', 'Call_shapka')
        }
      }
    },
  },
}
</script>