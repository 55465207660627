<script>
import request from '@/request'
export default {
  name: 'vue-analog-products',
  props: ['article', 'title'],
  created () {
    const crhosts = ['devcr.dtlshop.ru', 'chemicalrussian.ru'];
    if (!crhosts.includes(location.host)) {
      this.getItems()
    }
  },
  data() {
    return {
      code:'eb1cb008e65e2e832dda9f270e711f68',
      limit: 8,
      html: ''
    }
  },
  methods: {
    getItems() {
      const params = {}
      params.item = this.article
      params.limit = this.limit

      window.r46("recommend", this.code, params, (response) => {
        if (response.recommends !== undefined && response.recommends.length > 0) {
          this.getHtml(response.recommends)
          setTimeout(() => {
            this.initSlider()
          }, 700 )
        }
      })
    },
    async getHtml(articles) {
      const formdata = new FormData();
      formdata.append('articles', articles)
      const result = await request.get('r46/products', formdata);
      if (result.data.success === true) {
        this.html = result.data.html

        setTimeout(() => {
          if (typeof favorites != "undefined") {
            // eslint-disable-next-line no-undef
            favorites.init();
          }
          if (typeof Cart != "undefined") {
            // eslint-disable-next-line no-undef
            Cart.init();
          }
        }, 500)
      }
    },

    initSlider() {
      new window.Swiper('.products__swiper', {
        loop: true,
        slidesPerView: 1.4,
        breakpoints: {
          // when window width is >= 375px
          360: {
            slidesPerView: 1.6,
            spaceBetween: 16
          },
          576: {
            slidesPerView: 1.6,
            spaceBetween: 24,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 24,
          },
          992: {
            slidesPerView: 5,
            spaceBetween: 24,
            navigation: {
              nextEl: '.swiper__next',
              prevEl: '.swiper__prev',
            },
          },
          1200: {
            slidesPerView: 6,
            spaceBetween: 24,
          },
        },
        navigation: {
          nextEl: '.swiper__next',
          prevEl: '.swiper__prev',
        },
      });
    },
  }
}
</script>

<template>
  <div class="d-flex flex-wrap  productPage__additional  products" v-if="html !== ''">
    <p class="header_1 products__title">Аналоги товара {{ title }}</p>

    <div class="swiper products__swiper">
      <div class="products__grid  swiper-wrapper mb-4" v-html="html"></div>

      <button class="swiper__nav swiper__prev">
        <i class="fa-solid fa-chevron-left"></i>
      </button>

      <button class="swiper__nav swiper__next">
        <i class="fa-solid fa-chevron-right"></i>
      </button>
    </div>
  </div>
</template>