<template>
  <div class="modal__container">
    <button class="modal__close" aria-label="Close modal" data-micromodal-close>
      <svg style="height: 16px; width: 16px;">
        <use xlink:href="assets/template2/images/symbol/sprite.svg#close"></use>
      </svg>
    </button>

    <header class="modal__header">
      <h2 class="modal__title" id="modal-auth2-title">
        Вход<br> или регистрация
      </h2>
    </header>

    <template v-if="activeComponent === 'PhoneInput'">
      <PhoneInput
          @changeInput="changeInput"
          :clientPhone="phone"
          :view="'modal'"
      />
    </template>

    <template v-if="activeComponent === 'SmsInput'">
      <SmsInput
          @changeInput="changeInput"
          :userExists="userExists"
          :clientPhone="phone"
          :view="'modal'"
      />
    </template>

    <template v-if="activeComponent === 'PasswordInput'">
      <PasswordInput
          @changeInput="changeInput"
          :view="'modal'"
      />
    </template>

    <template v-if="activeComponent === 'EmailCodeInput'">
      <EmailCodeInput
          @changeInput="changeInput"
          :userExists="userExists"
          :clientEmail="email"
          :view="'modal'"
      />
    </template>
  </div>
</template>

<script>

import PhoneInput from './PhoneInput'
import SmsInput from './SmsInput'
import PasswordInput from './PasswordInput'
import EmailCodeInput from './EmailCodeInput.vue'

export default {
  name: "ModalAuth",
  components: {
    PhoneInput,
    SmsInput,
    PasswordInput,
    EmailCodeInput
  },
  data() {
    return {
      activeComponent: 'PhoneInput',
      phone: '',
      email: '',
      userExists: false
    }
  },
  methods: {
    changeInput(data) {
      this.activeComponent = data.input
      if (data.phone) {
        this.phone = data.phone
      }
      if (data.email) {
        this.email = data.email
      }
      if (data.user_exists) {
        this.userExists = data.user_exists
      }
    }
  },
}
</script>