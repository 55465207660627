<template>
  <div>
    <h1 class="officePage__title">Профиль / Изменение пароля</h1>

    <div class="officePage__nav">
      <ul class="officePage__navList">
        <li class="officePage__navItem"
          :class="{'officePage__navItem--active' : activeComponent === 'ProfileForm'}"
        >
          <button class="officePage__navButton" @click="setComponent('ProfileForm')">Профиль</button>
        </li>
        <li class="officePage__navItem"
            :class="{'officePage__navItem--active' : activeComponent === 'PasswordForm'}"
        >
          <button class="officePage__navButton" @click="setComponent('PasswordForm')">Сменить пароль</button>
        </li>
      </ul>
    </div>


    <template v-if="pageReady">
      <template v-if="activeComponent === 'ProfileForm'">
        <ProfileForm
          :user_id="user_id"
        />
      </template>
      <template v-if="activeComponent === 'PasswordForm'">
        <PasswordForm
          :user_id="user_id"
        />
      </template>
    </template>
  </div>
</template>

<script>
import ProfileForm from "./ProfileForm";
import PasswordForm from "./PasswordForm";
export default {
  name: "dtl-Profile",
  components: {
    ProfileForm,
    PasswordForm
  },
  created() {
    if (this.$parent.user_id !== undefined) {
      this.user_id = parseInt(this.$parent.user_id)
      this.pageReady = true
    }
  },
  data() {
    return {
      user_id: 0,
      pageReady: false,
      activeComponent: 'ProfileForm'
    }
  },
  methods: {
    setComponent(activeComponent) {
      this.activeComponent = activeComponent
    },
  },
}
</script>

<style scoped>

</style>