<template>
  <div>
    <div v-if="view === 'modal'">
      <div class="modal__content" id="modal-auth2-content">
        <p class="modal__text">На номер <strong>{{ clientPhone }}</strong> отправлено СМС</p>

        <div class="form__otp">
          <OtpInput
              ref="otpInput"
              input-classes="otp-input"
              separator="-"
              :num-inputs="4"
              :should-auto-focus="true"
              :is-input-num="true"
              @on-complete="handleOnComplete"
              name="otp"
          ></OtpInput>
        </div>

        <p class="modal__text" v-if="validationFeedBack !== null"><strong>{{ validationFeedBack }}</strong></p>

        <template v-if="userExists === true">
          <a href="#" class="modal__link"
             @click.prevent="$emit('changeInput', {input:'PasswordInput'})"
          >Войти с помощью пароля</a>
        </template>
        <template v-else>
          <p class="modal__text">
            Пользователь с номером <strong>{{ clientPhone }}</strong> не найден.
          </p>

          <p class="modal__text">
            После подтверждения номера телефона
            мы зарегистрируем для вас новый профиль.
          </p>
        </template>
      </div>


      <div class="modal__footer">

        <button class="button button--blue modal__sendAgain"
          v-if="timerIsActive"
                disabled
        >Запросить код повторно через&nbsp;
          <timer
              @stopTimer="stopTimer()"
          />
          &nbsp;сек</button>

        <button class="button button--blue modal__sendAgain" v-else
          @click="sendSms"
        >Запросить код повторно</button>


        <button class="button button--gray modal__button"
                @click="$emit('changeInput', {input:'PhoneInput'})"
        >Назад</button>
      </div>

      <div class="loader" v-if="loading">
        <span class="loader__indicator"></span>
      </div>
    </div>

    <div v-if="view === 'page'">
      <div class="alert alert-primary" role="alert">
        На номер <strong>{{ clientPhone }}</strong> отправлено СМС
      </div>

      <div class="d-flex justify-content-center">
        <OtpInput
            ref="otpInput"
            input-classes="otp-input"
            separator="-"
            :num-inputs="4"
            :should-auto-focus="true"
            :is-input-num="true"
            @on-complete="handleOnComplete"
            name="otp"
        ></OtpInput>
      </div>
      <div class="alert alert-danger my-4" role="alert" v-if="validationFeedBack !== null">
        <strong>{{ validationFeedBack }}</strong>
      </div>

      <template v-if="userExists === true">
        <div class="d-flex justify-content-between">
          <a href="#" class="btn btn-link mt-3"
             @click.prevent="$emit('changeInput', {input:'PasswordInput'})"
          >Войти с помощью пароля</a>
          <button type="button" class="btn btn-outline-danger mt-3"
                  @click="$emit('changeInput', {input:'PhoneInput'})"
          >Назад
          </button>
        </div>
      </template>
      <template v-else>
        <div class="alert alert-warning my-4" role="alert">
          Пользователь с номером <strong>{{ clientPhone }}</strong> не найден. <br> После подтверждения номера телефона
          мы зарегистрируем для вас новый профиль.
        </div>
        <button type="button" class="btn btn-outline-danger"
                @click="$emit('changeInput', {input:'PhoneInput'})"
        >Назад
        </button>
      </template>

      <div
          v-if="loading"
          class="spinner-border text-danger login__form-spinner" role="status">
        <span class="sr-only">Loading...</span>
      </div>

    </div>
  </div>
</template>

<script>
import OtpInput from "@bachdgvn/vue-otp-input";
import request from "@/request";
import Timer from "../Widgets/Timer"

export default {
  name: "SmsInput",
  components: {
    OtpInput,
    Timer
  },
  props: ['userExists', 'clientPhone', 'view'],
  created() {
    this.timerIsActive = true
  },
  data() {
    return {
      loading: false,
      validationFeedBack: null,
      timerIsActive: false
    }
  },
  methods: {
    async sendSms() {
      this.loading = true
      const formdata = new FormData()
      formdata.append('phone', this.clientPhone)
      const response = await request.post('auth/sms', formdata);
      if (response.data.success === true) {
        this.timerIsActive = true
        this.loading = false
      }
    },
    async handleOnComplete(value) {
      this.loading = true
      this.validationFeedBack = null
      const formdata = new FormData()
      formdata.append('code', value)
      formdata.append('phone', this.clientPhone)
      const response = await request.post('auth/check-code', formdata)

      if (response.data.success === false) {
        this.loading = false
        this.validationFeedBack = response.data.message
      }

      if (response.data.success === true) {
        this.loading = false
        await this.auth()
      }
    },
    async auth() {
      const formdata = new FormData()
      formdata.append('phone', this.clientPhone)
      formdata.append('action', 'by_phone')
      const response = await request.post('auth', formdata)
      if (response.data.success === true) {
        if (this.userExists === true) {
          window.ym(44716432, 'reachGoal', 'registering_new_client')
        }

        location.reload()
      }
      if (response.data.success === false && response.data.message) {
        this.validationFeedBack = response.data.message
      }
    },
    stopTimer() {
      this.timerIsActive = false
    },
  },
}
</script>