<template>
    <div :class="groupClasses">
        <label for="city" class="form__label" v-if="label !== ''">Город</label>
        <input type="text" class="form__input vue-dadata__input" placeholder="Введите город"
               v-model="value"
               @input="dadatarequest"
        >
        <template v-if="suggestions.length > 0">
            <ul class="location__suggestions suggestions">
                <li v-for="item in suggestions"
                    :key="item.key"
                    @click="changeCity(item)"
                    class="suggestions__item"
                >{{ item.value }}
                </li>
            </ul>
        </template>
    </div>
</template>

<script>
export default {
  name: 'dtl-dadata',
  props: ['city', 'label', 'formGroupClasses'],
  created () {
    if (this.city !== undefined) {
      this.value = this.city
    }
    if (this.formGroupClasses !== undefined) {
      this.groupClasses = this.formGroupClasses
    }
  },

  data () {
    return {
      value: '',
      suggestions: [],
        groupClasses: 'vue-dadata form__group location'
    }
  },
  methods: {
    dadatarequest () {
      const url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address'
      const token = '8cc6a602befe4bd84bfb867b4dd92a2f5bab8c7c'
      const query = this.value
      if (this.value.length < 3) {
        return
      }
      this.suggestions = []
      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Token ' + token
        },
        body: JSON.stringify({
          query: query,
          locations: [{ country: 'Россия' }],
          from_bound: { 'value': 'city' },
          to_bound: { 'value': 'city' },
        })
      }

      fetch(url, options)
        .then(response => response.json())
        .then(result => {
          if (result.suggestions.length > 0) {
            this.suggestions = []
            result.suggestions.forEach(item => {
              this.suggestions.push({
                key: item.value,
                value: item.value,
                region: item.data.region_iso_code,
                city: item.data.city,
              })
            })
          }
        })
        .catch(error => console.log('error', error))
    },
    changeCity (item) {
      this.value = item.city
      this.$emit('changeCity', item.city)
      this.suggestions = []
    }
  }
}
</script>