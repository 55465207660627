<template>
  <div class="orderPage">
    <div class="orderPage__top">
      <div class="orderPage__topLeft">
        <h1 class="orderPage__header">Оформление заказа</h1>
        <div class="orderPage__checkout checkout form" v-if="orderReady">
          <div class="checkout__stepOne">
            <div class="form__section">
              <div class="form__group">
                <label for="checkoutCountry" class="form__label">Страна</label>

                <select id="checkoutCountry" class="form__input"
                  @change="changeCountry"
                        v-model="order.country"
                >
                  <option value="Россия" :selected="order.country === 'Россия'">Россия</option>
                  <option value="Беларусь" :selected="order.country === 'Беларусь'">Беларусь</option>
                  <option value="Казахстан" :selected="order.country === 'Казахстан'">Казахстан</option>
                </select>
              </div>

              <div class="form__group" v-if="order.country === 'Россия'">
                <label for="checkoutCity" class="form__label">Город</label>
                <input type="text" autofocus style="display: none">
                  <dadata
                    :city="order.city"
                    :label="''"
                    :formGroupClasses="'vue-dadata location'"
                    @changeCity="changeCity"
                  />
              </div>
              <div class="form__group" v-else>
                <label for="checkoutCity" class="form__label">Город</label>
                <input type="text" class="form__input" @change="changeCity($event.target.value)" :value="order.city">

              </div>
            </div>
          </div>

          <div class="checkout__stepTwo">
            <p class="checkout__title">Способ доставки</p>

            <div class="checkout__radios">
              <div class="checkout__radio"
                   v-for="delivery in deliveries"
                   :key="delivery.id"
                   @click="setDelivery(delivery['id'])"
                   :class="{'checkout__radio--checked': delivery.checked}"
              >
                <input type="radio" :id="'delivery_' + delivery['id']" name="delivery" class="checkout__radioInput"
                       :checked="delivery.checked"
                >
                <span class="checkout__radioBefore"></span>

                <label :for="'delivery_' + delivery['id']" class="checkout__radioLabel" v-html="delivery.name"></label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="orderPage__topRight">
        <div class="orderPage__cart orderCart" v-if="cartReady">
          <div class="orderCart__header">
            <p class="orderCart__title">Заказ</p>
            <a href="/basket" class="orderCart__headerLink">Изменить</a>
          </div>

          <div class="orderCart__content">
            <div class="orderCart__row"
                 v-for="row in cart"
                 :key="row.key">
              <a :href="row.format.url">
                <img :src="row.format.image" alt="" title="" class="orderCart__image">
              </a>

              <a :href="row.format.url" class="orderCart__name">{{ row.format.name }}</a>

              <div class="orderCart__rowTotal">
                <div class="orderCart__rowTotalTop">
              <span class="orderCart__cost">{{ row.format.cost }}
              <span class="orderCart__rub">&#8381;</span>
            </span>
                </div>
                <div class="orderCart__rowTotalBottom">
                  <span class="orderCart__count">{{ row.count }}</span>
                  x
                  <span class="orderCart__price">{{ row.format.price }}
              <span class="orderCart__rub">&#8381;</span>
            </span>
                </div>
              </div>
            </div>
          </div>

          <div class="orderCart__promocode" v-if="status.total_discount > 0">
            <span class="orderCart__promocodeTitle">Скидка по промокоду</span>
            <span class="orderCart__promocodeDiscount">{{ status.format.total_discount }} &#8381;</span>
          </div>

          <div class="orderCart__total">
            <span class="orderCart__totalTitle">Итого</span>
            <span class="orderCart__totalPrice">{{ status.format.total_cost }} &#8381;</span>
          </div>
        </div>
      </div>
    </div>

    <template v-if="orderReady">
      <template v-if="activeComponent === 'Pickup'">
        <Pickup/>
      </template>
      <template v-if="activeComponent === 'SPBcourier'">
        <SPBcourier/>
      </template>
      <template v-if="activeComponent === 'Pickpoint'">
        <Pickpoint/>
      </template>
      <template v-if="activeComponent === 'Courier'">
        <Courier />
      </template>
    </template>
  </div>
</template>

<script>
import request from "@/request";
import Pickup from "./Pickup";
import SPBcourier from "./SPBcourier";
import Pickpoint from "./Pickpoint";
import Courier from "./Courier";
import dadata from '../Widgets/dadata.vue'

export default {
  name: "dtl-Order",
  components: {
    dadata,
    Pickup,
    SPBcourier,
    Pickpoint,
    Courier
  },
  created() {
    if (this.$parent.order_id !== undefined) {
      this.order_id = parseInt(this.$parent.order_id)
      this.getCart()
      this.getOrder()
    }
  },
  watch: {
    orderReady() {
      switch (this.order.delivery) {
        case 1:
          this.changeInput({input: 'Pickup'})
          break;
        case 2:
          this.changeInput({input: 'Pickpoint'})
          break;
        case 3:
          this.changeInput({input: 'Courier'})
          break;
        case 5:
          this.changeInput({input: 'SPBcourier'})
          break;
        default:
          this.activeComponent = ''
      }
    },
  },
  data() {
    return {
      order_id: 0,
      cart: {},
      cartReady: false,
      orderReady: false,
      status: {},
      order: {},
      deliveries: [],
      activeComponent: '',
      payments: [],
      city: ''
    }
  },
  methods: {
    async getCart() {
      const formData = new FormData();
      if (this.order_id !== '') {
        formData.append('order_id', this.order_id)
      }
      const response = await request.get('order/cart/get', formData)
      if (response.data.success === true) {
        this.cartReady = true
        this.cart = response.data.cart
        this.status = response.data.status
      }
    },
    async getOrder() {
      const formData = new FormData();
      if (this.order_id !== '') {
        formData.append('order_id', this.order_id)
      }
      const response = await request.get('order/get', formData)
      if (response.data.success === true) {
        this.orderReady = true
        this.order = response.data.order
        this.deliveries = response.data.deliveries
        this.payments = response.data.payments
      }
    },
    async changeCity(value) {
      this.city = value
      await this.setCity(this.city)
    },
    async setDelivery(id) {
      this.order.delivery = id
      this.deliveries.forEach(item => item.checked = item.id === id)

      const formData = new FormData();
      if (this.order_id !== '') {
        formData.append('order_id', this.order_id)
      }
      formData.append('delivery', id)
      await request.post('order/update', formData)
      switch (this.order.delivery) {
        case 1:
          this.changeInput({input: 'Pickup'})
          break;
        case 2:
          this.changeInput({input: 'Pickpoint'})
          break;
        case 3:
          this.changeInput({input: 'Courier'})
          break;
        case 5:
          this.changeInput({input: 'SPBcourier'})
          break;
        default:
          this.activeComponent = ''
      }
    },
    async setCity(city) {
      if (city === '') {
        return false;
      }
      this.orderReady = false
      const formData = new FormData();
      if (this.order_id !== '') {
        formData.append('order_id', this.order_id)
      }
      formData.append('city', city)
      const response = await request.post('order/update', formData)
      if (response.data.success === true) {
        await this.getOrder()
      }
    },
    changeInput(data) {
      this.getOrder()
      this.activeComponent = data.input
    },
    async orderUpdate(key, value) {
      const formData = new FormData();
      if (this.order_id !== '') {
        formData.append('order_id', this.order_id)

        formData.append(key, value)
      }
      await request.post('order/update', formData)
    },
    async changeCountry() {
      this.orderReady = false
      const formData = new FormData();
      if (this.order_id !== '') {
        formData.append('order_id', this.order_id)
      }
      formData.append('country', this.order.country)
      const response = await request.post('order/update', formData)
      if (response.data.success === true) {
        await this.getOrder()
      }
    },
  }
}
</script>