<template>
  <div>
    <div v-if="view === 'modal'">
      <div class="modal__content" id="modal-auth2-content">
        <p class="modal__text">На Email <strong>{{ clientEmail }}</strong> отправлено сообщение с проверочным кодом</p>

        <div class="form__otp">
          <OtpInput
              ref="otpInput"
              input-classes="otp-input"
              separator="-"
              :num-inputs="4"
              :should-auto-focus="true"
              :is-input-num="true"
              @on-complete="handleOnComplete"
              name="otp"
          ></OtpInput>
        </div>

        <p class="modal__text" v-if="validationFeedBack !== null"><strong>{{ validationFeedBack }}</strong></p>

        <template v-if="userExists === true">
          <a href="#" class="modal__link"
             @click.prevent="$emit('changeInput', {input:'PasswordInput'})"
          >Назад</a>
        </template>
        <template v-else>
          <p class="modal__text">
            Пользователь с Email <strong>{{ clientEmail }}</strong> не найден.
          </p>

          <p class="modal__text">
            После подтверждения Email
            мы зарегистрируем для вас новый профиль.
          </p>
        </template>
      </div>


      <div class="modal__footer">
        <button class="button button--gray modal__button"
                @click="$emit('changeInput', {input:'PhoneInput'})"
        >Назад</button>
      </div>

      <div class="loader" v-if="loading">
        <span class="loader__indicator"></span>
      </div>
    </div>

  </div>
</template>

<script>
import OtpInput from "@bachdgvn/vue-otp-input";
import request from "@/request";

export default {
  name: "SmsInput",
  components: {
    OtpInput,
  },
  props: ['userExists', 'clientEmail', 'view'],
  data() {
    return {
      loading: false,
      validationFeedBack: null,
    }
  },
  methods: {
    async handleOnComplete(value) {
      this.loading = true
      this.validationFeedBack = null
      const formdata = new FormData()
      formdata.append('code', value)
      formdata.append('email', this.clientEmail)
      const response = await request.post('auth/check-email-code', formdata)

      if (response.data.success === false) {
        this.loading = false
        this.validationFeedBack = response.data.message
      }

      if (response.data.success === true) {
        this.loading = false
        await this.auth()
      }
    },
    async auth() {
      const formdata = new FormData()
      formdata.append('email', this.clientEmail)
      formdata.append('action', 'by_email')
      const response = await request.post('auth', formdata)
      if (response.data.success === true) {
        if (this.userExists === true) {
          const crhosts = ['devcr.dtlshop.ru', 'chemicalrussian.ru'];
          if (!crhosts.includes(location.host)) {
            window.ym(44716432, 'reachGoal', 'registering_new_client')
          }

        }

        location.reload()
      }
      if (response.data.success === false && response.data.message) {
        this.validationFeedBack = response.data.message
      }
    },
  },
}
</script>