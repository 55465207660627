<template>
  <div>
    <div v-if="Object.keys(cart).length  > 0">
      <div class="cartPage__headerWrapper">
        <div class="back">
          <a href="#" class="back__button"><i class="fa-solid fa-chevron-left"></i></a>
        </div>

        <p class="cartPage__header header_3">Товаров в корзине: {{ status.total_count }}</p>
      </div>

      <div class="cartPage__content" v-if="cartReady">
        <div class="cartPage__cart msCart">
          <div class="msCart__content">
            <div class="msCart__table">
              <div class="msCart__tr"
                   v-for="row in cart"
                   :key="row.key"
                   :class="{'msCart__tr--not-available': !row.available}"
              >
                <div class="msCart__td msCart__td--one">
                  <a :href="row.format.url">
                    <img :src="row.format.image" alt="" title="" class="msCart__image">
                  </a>
                </div>
                <div class="msCart__td msCart__content msCart__td--two">
                  <div class="msCart__contentLeft">
                    <div class="msCart__name">
                      <a :href="row.format.url">{{ row.properties.name }}</a>
                    </div>

                    <div class="d-flex justify-content-between align-items-end">
                      <div class="msCart__reviews reviews">
                        <div class="reviews__stars">
                          <i
                              v-for="(star, index) in row.properties.product.rating.stars"
                              :key="index"
                              :class="star"></i>
                        </div>

                        <span class="reviews__text">({{ row.properties.product.messages_count }} отзывов)</span>
                      </div>

                      <div class="msCart__available" v-if="row.available">
                        <img src="https://dtlshop.ru/assets/template2/images/available.png" alt="">
                        <span class="msCart__availableText"> В наличии</span>
                      </div>

                      <div class="msCart__notAvailable" v-else>
                        <img src="https://dtlshop.ru/assets/template2/images/not-available.png" alt="">
                        <span class="msCart__availableText">Нет в наличии</span>
                      </div>
                    </div>
                  </div>
                  <div class="msCart__contentRight">
                    <button class="msCart__favorite favorite__item" type="button"
                            :class="{'favorite__item--active': row.favorite}"
                            @click="toggleFavorite(row.id)"
                    >
                      <i class="fa-regular fa-heart"></i>
                      <i class="fa-solid fa-heart"></i>
                    </button>

                    <button class="msCart__remove" type="button"
                            @click="remove(row.key)"
                    >
                      <i class="fa-regular fa-trash-can"></i>
                    </button>
                  </div>
                </div>
                <div class="msCart__td msCart__td--three">
                  <div class="d-flex">
                    <form class="msCart__update cartCounts"
                          v-if="row.available"
                          @submit.prevent
                    >
                      <button class="cartCounts__button " type="button"
                              @click="change(row.key, row.count - 1)"
                      >-
                      </button>
                      <input type="number" name="count" class="cartCounts__input"
                             :value="row.count"
                             @keyup.enter="change(row.key, $event.target.value)"
                             @change="change(row.key, $event.target.value)"
                      >
                      <button class="cartCounts__button" type="button"
                              @click="change(row.key, row.count + 1)"
                      >+
                      </button>
                    </form>
                    <div v-else class="msCart__notAvailableText">
                      Товара нет в наличии
                    </div>

                    <div class="msCart__prices">
                      <p class="msCart__price">
                        {{ row.format.cost }}
                        <svg style="height: 11px; width: 13px;">
                          <use xlink:href="assets/template2/images/symbol/sprite.svg#rub"
                               class="msCart__rub"></use>
                        </svg>
                      </p>

                      <div class="msCart__bonuses" v-if="row.available">
                        <svg style="height: 11px; width: 10px;" class="msCart__bonusesIcon">
                          <use xlink:href="assets/template2/images/symbol/sprite.svg#wallet"></use>
                        </svg>

                        <span class="msCart__bonusesText">+{{ row.format.bonus_total }} <span>
                          {{ numWord(row.format.bonus_total, ['бонус', 'бонуса', 'бонусов']) }}
                        </span></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="cartPage__rightSide">
          <div class="cartPage__total cartTotal">
            <div class="cartTotal__content">
              <p class="cartTotal__text cartTotal__text--one">
                <span class="cartTotal__textKey"><span>{{ status.format.total_count }} {{ numWord(status.format.total_count, ['товар', 'товара', 'товаров']) }}</span></span>
                <span class="cartTotal__textValue"><span class="cartTotal__textValuePrice">{{
                    status.format.total_cost
                  }}  <span class="cartTotal__textValueRub">&#8381;</span></span></span>
              </p>

              <p class="cartTotal__text cartTotal__text--two">
                <span class="cartTotal__textKey"><span>Бонусных баллов</span></span>
                <span class="cartTotal__textValue"><span>{{ status.format.total_bonus }}</span></span>
              </p>

              <p class="cartTotal__text cartTotal__text--three" v-if="status.total_discount > 0">
                <span class="cartTotal__textKey"><span>Скидка</span></span>
                <span class="cartTotal__textValue"><span>{{ status.format.total_discount }}</span></span>
              </p>
            </div>


            <promocode
                :coupon="coupon"
                @updated="getCart"
            />


            <a class="button button--red cartTotal__submit"
               href="/checkout"
            >Оформить заказ</a>

            <cartOneClick
                :order_id="order_id"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="cartPage__headerWrapper">
        <div class="back">
          <a href="#" class="back__button"><i class="fa-solid fa-chevron-left"></i></a>
        </div>

        <p class="cartPage__header header_3">Корзина пуста</p>
      </div>
    </div>
  </div>
</template>

<script>
import request from '@/request'
import promocode from './Promocode'
import cartOneClick from '../BuyOneClick/CartOneClick.vue'

export default {
  name: 'dtl-Cart',
  components: { promocode, cartOneClick },
  created () {
    if (this.$parent.order_id !== undefined) {
      this.order_id = parseInt(this.$parent.order_id)
      this.getCart()
    }
  },
  data () {
    return {
      order_id: 0,
      cart: {},
      cartReady: false,
      coupon: '',
      status: {
        total_count: 0
      }
    }
  },
  methods: {
    async getCart () {
      const formData = new FormData()
      if (this.order_id !== '') {
        formData.append('order_id', this.order_id)
      }
      const response = await request.get('order/cart/get', formData)
      if (response.data.success === true) {
        this.cartReady = true
        this.cart = response.data.cart
        this.status = response.data.status
        if (response.data.status.promocode !== undefined) {
          this.coupon = response.data.status.promocode.coupon
        }
      }
    },
    async change (key, count) {
      const formData = new FormData()
      if (this.order_id !== '') {
        formData.append('order_id', this.order_id)
        formData.append('key', key)
        formData.append('count', count)
      }
      const response = await request.post('order/cart/change', formData)
      if (response.data.success === true) {
        if (count >= 1) {
          this.ecommerce('Add to Cart', this.cart[key])
        } else {
          this.ecommerce('Remove from Cart', this.cart[key])

          if (typeof window.r46 != "undefined") {
            window.r46('track', 'remove_from_cart', this.cart[key].properties.article);
          }
        }

        this.cart = response.data.cart
        this.status = response.data.status
        window.miniShop2.Message.success(response.data.message)
      }
      if (response.data.success === false) {
        window.miniShop2.Message.error(response.data.message)
      }
    },
    async remove (key) {
      const formData = new FormData()
      const row = this.cart[key]
      if (this.order_id !== '') {
        formData.append('order_id', this.order_id)
        formData.append('key', key)
      }
      const response = await request.post('order/cart/remove', formData)
      if (response.data.success === true) {
        this.ecommerce('Remove from Cart', row)

        if (typeof window.r46 != "undefined") {
          window.r46('track', 'remove_from_cart', row.properties.article);
        }

        this.cart = response.data.cart
        this.status = response.data.status
        window.miniShop2.Message.success(response.data.message)
      }
      if (response.data.success === false) {
        window.miniShop2.Message.error(response.data.message)
      }
    },
    async toggleFavorite (product_id) {
      const formData = new FormData()
      if (this.order_id !== '') {
        formData.append('order_id', this.order_id)
      }
      formData.append('product_id', product_id)
      const response = await request.post('order/cart/favorite', formData)
      if (response.data.success === true) {
        this.cart = response.data.cart
        this.status = response.data.status
        window.miniShop2.Message.success('Список избранного изменен')

        if (typeof window.r46 != "undefined") {
          if (response.data.action === 'add') {
            window.r46('track', 'wish', response.data.article);
          }
          if (response.data.action === 'remove') {
            window.r46('track', 'remove_wish', response.data.article);
          }
        }
      }
    },
    ecommerce (action, row) {
      window.dataLayer.push({
        'ecommerce': {
          'currencyCode': 'RUB',
          'add': {
            'products': [{
              'name': `${row.properties.name}`,
              'id': `${row.properties.article}`,
              'price': `${row.properties.price}`,
              'brand': `${row.properties.brand}`,
              'category': `${row.properties.category}`,
              'quantity': `${row.properties.count}`
            }]
          }
        },
        'event': 'ee_event',
        'ee_event_category': 'Enhanced Ecommerce',
        'ee_event_action': action,
        'ee_event_non-interaction': 'False',
      })
    },
    numWord(value, words) {
      value = Math.abs(value) % 100;
      const num = value % 10;
      if(value > 10 && value < 20) return words[2];
      if(num > 1 && num < 5) return words[1];
      if(num === 1) return words[0];
      return words[2];
    },
  },
}
</script>