<template>
  <div class="modal__container">
      <button class="modal__close" aria-label="Close modal" data-micromodal-close>
          <svg style="height: 18px; width: 18px;">
              <use xlink:href="/assets/template2/images/symbol/sprite.svg#close"></use>
          </svg>
      </button>

      <div class="modal__header">
          <p class="modal__title" id="modal-location-title">
              Ваш город или<br> населенный пункт
          </p>
      </div>

      <div class="modal__content" id="modal-location-content">
          <dadata
              :city="city"
              @changeCity="changeCity"
          />
          <p class="modal__text">Если мы неверно определили ваш город, можете указать верный вариант</p>

      </div>

      <div class="modal__footer">

          <button class="button button--red modal__button" @click="save">Сохранить изменения</button>

          <button type="button" class="button button--gray modal__button"  data-micromodal-close>Отмена</button>
      </div>
  </div>
</template>

<script>
import dadata from '../Widgets/dadata.vue'
import request from "@/request";

export default {
  name: "dtl-Location",
  created() {
    if (this.$parent.defaultCity !== undefined) {
      this.city = this.$parent.defaultCity
    }
  },
    components: {
        'dadata': dadata,
    },
  data() {
    return {
      city: '',
    }
  },
  methods: {
      changeCity(value) {
          this.city = value
      },
      async save(e) {
          e.preventDefault();

          const response = new Promise((resolve) => {
              const formdata = new FormData()
              formdata.append('city', this.city)
              request.post('user/change-city', formdata)
              resolve()
          })

          response.then(() => {
              //window.geolocation.init()
              setTimeout(() => {
                  location.reload()
              }, 500)
          })
      }
  },
}
</script>