<template>
  <div>
    <div v-if="view === 'modal'">
      <div class="modal__content" id="modal-auth2-content">
        <form class="modal__form">
          <div class="form__group">
            <input
                type="text"
                placeholder="Введите номер телефона или email"
                required="required"
                class="form__input modal__input"
                :class="{'form__input--valid': loginValid === true, 'form__input--invalid': loginValid === false}"
                @change="validate"
                v-model="login"
            >
          </div>

          <div class="form__group">
            <input
                type="password"
                placeholder="Введите пароль"
                required="required"
                class="form__input modal__input"
                v-model="password"
            >
          </div>

          <div
              v-if="loginFeedBack !== null"
              class="modal__text">
            {{ loginFeedBack }}
          </div>
        </form>
      </div>

      <div class="modal__footer">

        <button class="button button--red modal__button"
                :disabled="disabled"
                @click="send"
        >Войти</button>

        <button class="button button--gray modal__button"
                @click.prevent="$emit('changeInput', {input:'PhoneInput'})"
        >Назад</button>
      </div>

      <div class="loader" v-if="loading">
        <span class="loader__indicator"></span>
      </div>
    </div>
    <div v-if="view === 'page'">
      <div class="form-group">
        <label>Телефон или Email <span class="required">*</span></label>
        <input
            type="text"
            class="form-control"
            :class="{'is-valid': loginValid === true, 'is-invalid': loginValid === false}"
            placeholder="7XXXXXXXXX или email@mail.ru"
            @change="validate"
            v-model="login"
        >
      </div>
      <div class="form-group">
        <label>Пароль <span class="required">*</span></label>
        <input
            type="password"
            class="form-control"
            placeholder="Пароль"
            v-model="password"
        >
      </div>

      <div
          v-if="loginFeedBack !== null"
          class="alert alert-danger my-4" role="alert">
        {{ loginFeedBack }}
      </div>

      <div class="d-flex justify-content-between">
        <button type="button" class="btn btn-outline-danger"
                @click="$emit('changeInput', {input:'PhoneInput'})"
        >Назад
        </button>
        <button
            class="btn btn-success d-flex align-items-center"
            :disabled="disabled"
            @click="recaptcha"
        >
        <span v-if="loading" class="spinner-border spinner-border-sm spinner-btn mr-2" role="status"
              aria-hidden="true"></span>
          Войти
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/request";

export default {
  name: "PasswordInput",
  props: ['view'],
  components: {},
  watch: {
    login() {
      this.validate()
      this.disabled = !(this.loginValid === true && this.password.length >= 8);
    },
    password() {
      this.disabled = !(this.loginValid === true && this.password.length >= 8);
    },
  },
  data() {
    return {
      loginValid: null,
      login: '',
      password: '',
      loginFeedBack: null,
      disabled: true,
      loading: false,
    }
  },
  methods: {
    validate() {
      const validatedEmail = this.validateEmail()
      if (validatedEmail !== null) {
        this.loginValid = true
        return;
      }
      const validatedPhone = this.validatePhone()
      this.loginValid = validatedPhone !== null
    },
    validateEmail() {
      return String(this.login)
          .toLowerCase()
          .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    },
    validatePhone() {
      return String(this.login)
          .toLowerCase()
          .match(
              /^[+]?[(]?\d{3}[)]?[-\s.]?\d{3}[-\s.]?\d{4,6}$/im
          )
    },
    async send(token) {
      this.loading = true
      this.disabled = true
      this.loginFeedBack = null

      const formdata = new FormData()
      formdata.append('login', this.login)
      formdata.append('password', this.password)
      formdata.append('token', token)
      formdata.append('isdev', process.env.NODE_ENV !== 'production')
      const response = await request.post('auth/password', formdata);

      if (response.data.success === false) {
        this.loading = false
        this.disabled = false
        this.loginFeedBack = response.data.message
      }

      if (response.data.success === true) {
        if (response.data.user_exists === false) {
          if (response.data.is_email === true) {
            this.$emit('changeInput', {
              email: this.login,
              input: 'EmailCodeInput',
              user_exists: response.data.user_exists,
            })
          }

          if (response.data.is_phone === true) {
            this.$emit('changeInput', {
              phone: this.login,
              input: 'SmsInput',
              user_exists: response.data.user_exists,
            })
          }
        } else {
          location.reload()
        }
      }
    },
    async recaptcha() {
      await this.$recaptchaLoaded()
      const token = await this.$recaptcha('send')
      await this.send(token);
    },
  },
}
</script>