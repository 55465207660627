var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"orderPage__bottom orderPage__checkout checkout form checkout__stepTwo"},[(_vm.loading)?_c('div',{staticClass:"loader"},[_c('span',{staticClass:"loader__indicator"})]):_vm._e(),_c('div',{staticClass:"orderPage__bottomLeft"},[_c('p',{staticClass:"checkout__title"},[_vm._v("Способ оплаты")]),_c('div',{staticClass:"checkout__radios"},_vm._l((_vm.payments),function(item){return _c('div',{key:item.id,staticClass:"checkout__radio",class:{'checkout__radio--checked': item.checked},on:{"click":function($event){return _vm.setPayment(item['id'])}}},[_c('input',{staticClass:"checkout__radioInput",attrs:{"type":"radio","id":'payment_' + item['id']},domProps:{"checked":item.checked}}),_c('span',{staticClass:"checkout__radioBefore"}),_c('label',{staticClass:"checkout__radioLabel",attrs:{"for":'payment_' + item['id']},domProps:{"innerHTML":_vm._s(item.name)}})])}),0),_c('p',{staticClass:"checkout__title"},[_vm._v("Данные получателя:")]),_c('div',{staticClass:"form__section"},[_c('div',{staticClass:"form__group"},[_c('label',{staticClass:"form__label",attrs:{"for":"receiver"}},[_vm._v("Имя")]),_c('input',{staticClass:"form__input form__control",class:{
               'form__input--invalid' : _vm.errors.includes('receiver')
             },attrs:{"type":"text","placeholder":"Имя","id":"receiver"},domProps:{"value":_vm.order.receiver},on:{"change":function($event){return _vm.orderUpdate('receiver', $event.target.value)}}})]),_c('div',{staticClass:"form__group"},[_c('label',{staticClass:"form__label",attrs:{"for":"lastname"}},[_vm._v("Фамилия")]),_c('input',{staticClass:"form__input form__control",class:{
               'form__input--invalid' : _vm.errors.includes('lastname')
             },attrs:{"type":"text","placeholder":"Фамилия","id":"lastname"},domProps:{"value":_vm.order.lastname},on:{"change":function($event){return _vm.orderUpdate('lastname', $event.target.value)}}})])]),_c('div',{staticClass:"form__section"},[_c('div',{staticClass:"form__group"},[_c('label',{staticClass:"form__label",attrs:{"for":"phone"}},[_vm._v("Номер телефона")]),_c('vue-tel-input',{attrs:{"defaultCountry":'ru',"mode":'international',"validCharactersOnly":true,"dropdownOptions":_vm.telDropdownOptions,"inputOptions":_vm.telInputOptions,"styleClasses":_vm.telInputStyleClasses},on:{"validate":_vm.telInputValidate,"input":_vm.telInputMask},model:{value:(_vm.telInputValue),callback:function ($$v) {_vm.telInputValue=$$v},expression:"telInputValue"}})],1),_c('div',{staticClass:"form__group"},[_c('label',{staticClass:"form__label",attrs:{"for":"email"}},[_vm._v("Email")]),_c('input',{staticClass:"form__input form__control",class:{
               'form__input--invalid' : _vm.errors.includes('email')
             },attrs:{"type":"email","placeholder":"Email","id":"email"},domProps:{"value":_vm.order.email},on:{"change":function($event){return _vm.orderUpdate('email', $event.target.value)}}})])]),_c('p',{staticClass:"checkout__title"},[_vm._v("Адрес доставки:")]),_c('div',{staticClass:"form__section"},[_c('div',{staticClass:"form__group"},[_c('label',{staticClass:"form__label",attrs:{"for":"street"}},[_vm._v("Улица")]),_c('input',{staticClass:"form__input form__control",class:{
               'form__input--invalid' : _vm.errors.includes('street')
             },attrs:{"type":"text","placeholder":"Улица","id":"street"},domProps:{"value":_vm.order.street},on:{"change":function($event){return _vm.orderUpdate('street', $event.target.value)}}})]),_c('div',{staticClass:"form__group"},[_c('label',{staticClass:"form__label",attrs:{"for":"building"}},[_vm._v("Дом")]),_c('input',{staticClass:"form__input form__control",class:{
               'form__input--invalid' : _vm.errors.includes('building')
             },attrs:{"type":"text","placeholder":"Дом","id":"building"},domProps:{"value":_vm.order.building},on:{"change":function($event){return _vm.orderUpdate('building', $event.target.value)}}})]),_c('div',{staticClass:"form__group"},[_c('label',{staticClass:"form__label",attrs:{"for":"room"}},[_vm._v("Кв \\ оф")]),_c('input',{staticClass:"form__input form__control",class:{
               'form__input--invalid' : _vm.errors.includes('room')
             },attrs:{"type":"text","placeholder":"Кв \\ оф","id":"room"},domProps:{"value":_vm.order.room},on:{"change":function($event){return _vm.orderUpdate('room', $event.target.value)}}})])]),_c('div',{staticClass:"form__group w-100"},[_c('label',{staticClass:"form__label",attrs:{"for":"email"}},[_vm._v("Комментарий к заказу")]),_c('textarea',{staticClass:"form__textarea w-100",class:{
               'form__input--invalid' : _vm.errors.includes('comment')
             },attrs:{"id":"comment"},domProps:{"innerHTML":_vm._s(_vm.order.comment)},on:{"change":function($event){return _vm.orderUpdate('comment', $event.target.value)}}})]),_c('button',{staticClass:"button button--red",on:{"click":_vm.submit}},[_vm._v("Сделать заказ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }