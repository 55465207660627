<template>
  <div class="orderPage__bottom orderPage__checkout checkout form  checkout__stepTwo ">
    <div class="loader" v-if="loading">
      <span class="loader__indicator"></span>
    </div>
    <div class="orderPage__bottomLeft">
      <p class="checkout__title">Способ оплаты</p>
      <div class="checkout__radios">
        <div class="checkout__radio"
             v-for="item in payments"
             :key="item.id"
             @click="setPayment(item['id'])"
             :class="{'checkout__radio--checked': item.checked}"
        >
          <input type="radio" :id="'payment_' + item['id']" class="checkout__radioInput"
                 :checked="item.checked"
          >
          <span class="checkout__radioBefore"></span>

          <label :for="'payment_' + item['id']" class="checkout__radioLabel" v-html="item.name"></label>
        </div>
      </div>

      <p class="checkout__title">Данные получателя:</p>

      <div class="form__section">
        <div class="form__group">
          <label for="receiver" class="form__label">Имя</label>
          <input type="text" class="form__input form__control" placeholder="Имя" id="receiver" :value="order.receiver"
                 @change="orderUpdate('receiver', $event.target.value)"
                 :class="{
                 'form__input--invalid' : errors.includes('receiver')
               }"
          >
        </div>

        <div class="form__group">
          <label for="lastname" class="form__label">Фамилия</label>
          <input type="text" class="form__input form__control" placeholder="Фамилия" id="lastname"
                 :value="order.lastname"
                 @change="orderUpdate('lastname', $event.target.value)"
                 :class="{
                 'form__input--invalid' : errors.includes('lastname')
               }"
          >
        </div>
      </div>

      <div class="form__section">
        <div class="form__group">
          <label for="phone" class="form__label">Номер телефона</label>
          <vue-tel-input
              :defaultCountry="'ru'"
              :mode="'international'"
              :validCharactersOnly="true"
              v-model="telInputValue"
              :dropdownOptions="telDropdownOptions"
              :inputOptions="telInputOptions"
              :styleClasses="telInputStyleClasses"
              @validate="telInputValidate"
              @input="telInputMask"
          />
        </div>

        <div class="form__group">
          <label for="email" class="form__label">Email</label>
          <input type="email" class="form__input form__control" placeholder="Email" id="email" :value="order.email"
                 @change="orderUpdate('email', $event.target.value)"
                 :class="{
                 'form__input--invalid' : errors.includes('email')
               }">
        </div>
      </div>

      <p class="checkout__title">Адрес доставки:</p>

      <div class="form__section">
        <div class="form__group">
          <label for="street" class="form__label">Улица</label>
          <input type="text" class="form__input form__control" placeholder="Улица" id="street" :value="order.street"
                 @change="orderUpdate('street', $event.target.value)"
                 :class="{
                 'form__input--invalid' : errors.includes('street')
               }"
          >
        </div>

        <div class="form__group">
          <label for="building" class="form__label">Дом</label>
          <input type="text" class="form__input form__control" placeholder="Дом" id="building"
                 :value="order.building"
                 @change="orderUpdate('building', $event.target.value)"
                 :class="{
                 'form__input--invalid' : errors.includes('building')
               }"
          >
        </div>

        <div class="form__group">
          <label for="room" class="form__label">Кв \ оф</label>
          <input type="text" class="form__input form__control" placeholder="Кв \ оф" id="room"
                 :value="order.room"
                 @change="orderUpdate('room', $event.target.value)"
                 :class="{
                 'form__input--invalid' : errors.includes('room')
               }"
          >
        </div>
      </div>

      <div class="form__group w-100">
        <label for="email" class="form__label">Комментарий к заказу</label>
        <textarea id="comment" class="form__textarea w-100" v-html="order.comment"
                  @change="orderUpdate('comment', $event.target.value)"
                  :class="{
                 'form__input--invalid' : errors.includes('comment')
               }"
        ></textarea>
      </div>

      <button class="button button--red"
              @click="submit"
      >Сделать заказ</button>
    </div>

  </div>
</template>

<script>
import {VueTelInput} from 'vue-tel-input'
import request from "@/request";

export default {
  name: "dtl-Courier",
  components: {
    VueTelInput,
  },
  created() {
    if (this.$parent.order_id !== undefined) {
      this.order_id = parseInt(this.$parent.order_id)
      this.getOrder()
    }
  },
  data() {
    return {
      loading: false,
      order: {},
      orderReady: false,
      payments: [],
      errors: [],
      phone: '',
      payment: 0,
      telInputValue: '',
      telInputStyleClasses: ['form__control'],
      telDropdownOptions: {
        showDialCodeInSelection: false,
        showFlags: true,
        showDialCodeInList: true,
        disabled: true
      },
      telInputOptions: {
        placeholder: '+7-___-___-__-__',
        required: true,
        name: 'phone'
      },
    }
  },
  methods: {
    telInputValidate(input) {
      this.disabled = !input.valid
      this.telInputStyleClasses = ['form__control']
      if (input.valid === true) {
        this.phone = input.number
        this.telInputStyleClasses.push('is-valid')
        this.orderUpdate('phone', this.phone)
      }
    },
    telInputMask(value) {
      if (value === '87') {
        this.telInputValue = '+77'
      }
      if (value === '89') {
        this.telInputValue = '+79'
      }
      if (value === '77') {
        this.telInputValue = '+77'
      }
      if (value === '79') {
        this.telInputValue = '+79'
      }
    },
    setPayment(id) {
      this.payment = id
      this.payments.forEach(item => item.checked = item.id === id)
      this.orderUpdate('payment', id)
    },
    async orderUpdate(key, value) {
      const errors = []
      this.errors.forEach(item => {
        if (item !== key) {
          errors.push(item)
        }
      })
      this.errors = errors
      const formData = new FormData();
      if (this.order_id !== '') {
        formData.append('order_id', this.order_id)
        formData.append(key, value)
      }
      const response = await request.post('order/update', formData)
      if (response.data.success === true) {
        const formData = new FormData();
        if (this.order_id !== '') {
          formData.append('order_id', this.order_id)
        }
        const response = await request.get('order/get', formData)
        if (response.data.success === true) {
          this.order = response.data.order
        }
      }
      if (response.data.success === false) {
        this.order[key] = ''
      }
    },
    async submit() {
      this.loading = true
      this.errors = []
      const formData = new FormData();
      if (this.order_id !== '') {
        formData.append('order_id', this.order.id)
      }
      const response = await request.post('order/submit', formData)
      if (response.data.success === false) {
        this.loading = false
        if (response.data.message !== undefined && response.data.message !== '') {
          window.miniShop2.Message.error(response.data.message)
        }

        response.data.data.forEach((error) => {
          switch(error) {
            case  'payment':
              window.miniShop2.Message.error('Выберите способ оплаты')
              break
            default:
              this.errors.push(error)
          }
        })
      }
      if (response.data.success === true) {
        this.loading = false
        this.setEcommerce(response.data)
        window.ym(44716432, 'reachGoal', 'placed_an_order')
        if (response.data.redirect !== undefined) {
          window.location.href = response.data.redirect
        }
      }
    },
    async getOrder() {
      this.loading = true
      const formData = new FormData();
      if (this.order_id !== '') {
        formData.append('order_id', this.order_id)
      }
      const response = await request.get('order/get', formData)
      if (response.data.success === true) {
        this.setOrderData(response.data)
        this.loading = false
      }
    },
    setOrderData(data) {
      this.orderReady = true
      this.order = data.order
      this.payments = data.payments

      this.telInputValue = data.order.phone
      this.setPayment(3)
      this.orderUpdate('delivery_provider', 'dtl');
    },
    setEcommerce(response) {
      const   dataLayerProducts = [];
      const r46Products = [];
      response.products.forEach(product => {
        dataLayerProducts.push({
          id: `${product.properties.article}`,
          price: product.properties.price,
          quantity: product.properties.count,
          name: `${product.properties.name}`,
          brand: `${product.properties.brand}`,
          category: `${product.properties.category}`
        });

        r46Products.push({
          id: `${product.properties.article}`,
          price: product.properties.price,
          amount: product.properties.count
        });
      });

      window.dataLayer.push({
        'ecommerce': {
          'currencyCode': 'RUB',
          'purchase': {
            'actionField': {
              'id': `${response.order.num}`,
              'affiliation': `${location.host}`,
              'revenue': response.order.cost,
              'tax': '0',
              'shipping': '0',
            },
            'products': dataLayerProducts
          }
        },
        'event': 'ee_event',
        'ee_event_category': 'Enhanced Ecommerce',
        'ee_event_action': 'Purchase',
        'ee_event_non-interaction': 'False',
      });

      const crhosts = ['devcr.dtlshop.ru', 'chemicalrussian.ru'];
      if (!crhosts.includes(location.host)) {
        window.r46('track', 'purchase', {
          products: r46Products,
          order: `${response.order.num}`,
          order_price: response.order.cost
        });
      }
    },
  }
}
</script>