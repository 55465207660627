<template>
  <span v-if="currentTime > 0">
    {{ currentTime }}
  </span>
</template>

<script>
export default {
  name: "dtl-Timer",
  data() {
    return {
      currentTime: 59,
      timer: null,
    }
  },
  mounted() {
    this.startTimer()
  },
  destroyed() {
    this.stopTimer()
  },
  methods: {
    startTimer() {
      this.timer = setInterval(() => {
        this.currentTime--
      }, 1000)
    },
    stopTimer() {
      clearTimeout(this.timer)
      this.$emit('stopTimer')
    },

  },
  watch: {
    currentTime(time) {
      if (time === 0) {
        this.stopTimer()
      }
    }
  },
}
</script>
