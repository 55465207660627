<template>
  <div class="productPage__oneclick  productOneClick">
    <p class="productOneClick__title">Купить товар в один клик</p>

    <form class="productOneClick__form">
      <vue-tel-input
          :defaultCountry="'ru'"
          :mode="'international'"
          :validCharactersOnly="true"
          v-model="telInputValue"
          :dropdownOptions="telDropdownOptions"
          :inputOptions="telInputOptions"
          :styleClasses="telInputStyleClasses"
          @validate="telInputValidate"
          @input="telInputMask"
      />
      <button class="productOneClick__button button button--black" type="button"
              :disabled="disabled"
              @click="send"
      >
        <i class="fa-solid fa-angles-right"></i>
      </button>

      <div
          v-if="phoneValidationFeedBack !== null"
          class="invalid-feedback">
        {{ phoneValidationFeedBack }}
      </div>
    </form>
  </div>
</template>

<script>
import {VueTelInput} from 'vue-tel-input'
import request from "@/request";

export default {
  name: "BuyOneClick",
  components: {
    VueTelInput,
  },
  created() {
    if (this.$parent.product !== undefined) {
      this.product = this.$parent.product
    }
  },
  data() {
    return {
      product: 0,
      phone: '',
      telInputValue: '',
      telInputStyleClasses: ['form__input'],
      telDropdownOptions: {
        showDialCodeInSelection: false,
        showFlags: false,
        showDialCodeInList: false,
        showSearchBox: false,
        disabled: true
      },
      telInputOptions: {
        placeholder: '+7-___-___-__-__',
        required: true,
        name: 'phone'
      },
      phoneValidationFeedBack: null,
      disabled: true,
      loading: false,
    }
  },
  methods: {
    telInputValidate(input) {
      this.disabled = !input.valid
      this.telInputStyleClasses = ['form__input']
      if (input.valid === true) {
        this.phone = input.number
        this.telInputStyleClasses.push('form__input--valid')
      }
    },
    telInputMask(value) {
      if (value === '87') {
        this.telInputValue = '+77'
      }
      if (value === '89') {
        this.telInputValue = '+79'
      }
      if (value === '77') {
        this.telInputValue = '+77'
      }
      if (value === '79') {
        this.telInputValue = '+79'
      }
    },
    async send() {
      this.loading = true
      this.disabled = true
      this.phoneValidationFeedBack = null
      this.telInputStyleClasses = ['form__input']

      const formdata = new FormData()
      formdata.append('phone', this.phone)
      formdata.append('pageId', this.product)
      const response = await request.post('product/one-click', formdata);

      if (response.data.success === false) {
        this.loading = false
        this.disabled = false

        if (response.data.message !== '') {
          this.telInputStyleClasses.push('form__input--invalid')
          this.phoneValidationFeedBack = response.data.message
        }
      }

      if (response.data.success === true) {
        this.loading = false
        this.disabled = false
        this.phone = ''
        this.telInputValue = ''
        this.setEcommerce(response.data)
        window.miniShop2.Message.success('Заявка отправлена')

        if (response.data.redirect !== undefined) {
          setTimeout(() => {
            window.location.href = response.data.redirect
          }, 500)
        }
      }
    },
    setEcommerce(response) {
      const   dataLayerProducts = [];
      response.products.forEach(product => {
        dataLayerProducts.push({
          id: `${product.properties.article}`,
          price: product.properties.price,
          quantity: product.properties.count,
          name: `${product.properties.name}`,
          brand: `${product.properties.brand}`,
          category: `${product.properties.category}`
        });
      });

      window.dataLayer.push({
        'ecommerce': {
          'currencyCode': 'RUB',
          'purchase': {
            'actionField': {
              'id': `${response.order.num}`,
              'affiliation': `${location.host}`,
              'revenue': response.order.cost,
              'tax': '0',
              'shipping': '0',
            },
            'products': dataLayerProducts
          }
        },
        'event': 'ee_event',
        'ee_event_category': 'Enhanced Ecommerce',
        'ee_event_action': 'Purchase',
        'ee_event_non-interaction': 'False',
      });

      const crhosts = ['devcr.dtlshop.ru', 'chemicalrussian.ru'];
      if (!crhosts.includes(location.host)) {
        window.ym(44716432, 'reachGoal', 'buy_in_1_click_card')
      }

    },
  },
}
</script>