import Vue from 'vue'

import Location from './components/ModalLocationSelect/Location';
import ModalAuth2 from "./components/ModalAuth2/ModalAuth";
import ModalCallback from "./components/ModalCallback/ModalCallback";
import ModalOpt from "./components/ModalOpt/ModalOpt";
import ModalLetter from "./components/ModalLetter/ModalLetter";
import BuyOneClick from "./components/BuyOneClick/BuyOneClick";
import Comment from "./components/Comment/Comment";
import Cart from "./components/Cart/Cart";
import Order from  "./components/Order/Order";
import Profile from "./components/Office/Profile/Profile";
import Analog from './components/Analog/Analog.vue'

import 'vue-tel-input/dist/vue-tel-input.css';
import VueFileAgent from 'vue-file-agent';
import { VueReCaptcha } from 'vue-recaptcha-v3'

Vue.use(VueFileAgent);
Vue.use(VueReCaptcha, { siteKey: '6Lfd9xwnAAAAANuB1QXFKcEChAeXMgIysn7LzwLE' })

Vue.config.productionTip = false

const $vueLocation = document.querySelector('#vue-location')
if ($vueLocation) {
    new Vue({
        el: '#vue-location',
        data() {
            return {
                defaultCity: $vueLocation.dataset.city
            }
        },
        template: '<Location/>',
        components: {
            Location
        }
    })
}

const $vueAuth = document.querySelector('#vue-auth')
if ($vueAuth) {
    new Vue({
        el: '#vue-auth',
        data() {
            return {}
        },
        template: '<ModalAuth2/>',
        components: {
            ModalAuth2
        }
    })
}

const $vueCallback = document.querySelector('#vue-callback')
if ($vueCallback) {
    new Vue({
        el: '#vue-callback',
        data() {
            return {}
        },
        template: '<ModalCallback/>',
        components: {
            ModalCallback
        }
    })
}

const $vueOpt = document.querySelector('#vue-opt')
if ($vueOpt) {
    new Vue({
        el: '#vue-opt',
        data() {
            return {}
        },
        template: '<ModalOpt/>',
        components: {
            ModalOpt
        }
    })
}

const $vueLetter = document.querySelector('#vue-letter')
if ($vueLetter) {
    new Vue({
        el: '#vue-letter',
        data() {
            return {}
        },
        template: '<ModalLetter/>',
        components: {
            ModalLetter
        }
    })
}

const oneClickForms = document.querySelectorAll('.vue-buyOneClick')
if (oneClickForms.length > 0) {
    oneClickForms.forEach($oneClickForm => {
            new Vue({
                el: '#' + $oneClickForm.id,
                data() {
                    return {
                        product: $oneClickForm.dataset.product
                    }
                },
                template: '<BuyOneClick/>',
                components: {
                    BuyOneClick
                }
            })
    })
}

const $vueComm = document.querySelector('#vue-comm')
if ($vueComm) {
    const product = $vueComm.dataset['product']
    new Vue({
        el: '#vue-comm',
        data() {
            return {
                product
            }
        },
        template: '<Comment/>',
        components: {
            Comment
        }
    })
}

const $cartPage = document.querySelector('#vue-cart-page')
if ($cartPage) {
    const order_id = $cartPage.dataset['orderId']
    new Vue({
        el: '#vue-cart-page',
        data() {
            return {
                order_id
            }
        },
        template: '<Cart/>',
        components: {
            Cart
        }
    })
}

const $orderPage = document.querySelector('#vue-order')
if ($orderPage) {
    const order_id = $orderPage.dataset['orderId']
    new Vue({
        el: '#vue-order',
        data() {
            return {
                order_id
            }
        },
        template: '<Order/>',
        components: {
            Order
        }
    })
}

const $officeProfilePage = document.querySelector('#vue-office-profile')
if ($officeProfilePage) {
    const user_id = $officeProfilePage.dataset['userId']
    new Vue({
        el: '#vue-office-profile',
        data() {
            return {
                user_id
            }
        },
        template: '<Profile/>',
        components: {
            Profile
        }
    })
}

const $vueAnalog = document.querySelector('#vue-analog')
if ($vueAnalog) {
    const analogVM = new Vue({
        el: '#vue-analog',
        data() {
            return {
                productId: null,
                article: null,
                title: null,
            }
        },
        template: '<Analog :productId="productId" :article="article" :title="title" @reset="productId = null; article = null; title = null"/>',
        components: {
            Analog
        }
    })

    document.addEventListener('click', (e) => {
        if (e.target.classList.contains('vue-analog-btn')) {
            const $btn = e.target;
            analogVM.$data.productId = $btn.dataset['productId']
            analogVM.$data.article = $btn.dataset['article']
            analogVM.$data.title = $btn.dataset['title']
        }

        if (e.target.classList.contains('fa-envelope')) {
            const $btn = e.target.closest('.vue-analog-btn')
            if ($btn) {
                analogVM.$data.productId = $btn.dataset['productId']
                analogVM.$data.article = $btn.dataset['article']
                analogVM.$data.title = $btn.dataset['title']
            }
        }
    });
}