<template>
  <div class="orderPage__bottom orderPage__checkout checkout form  checkout__stepTwo ">
    <div class="loader" v-if="loading">
      <span class="loader__indicator"></span>
    </div>
    <div class="orderPage__bottomLeft">
      <div class="">
        <p class="checkout__title">Способ оплаты</p>

        <div class="checkout__radios">
          <div class="checkout__radio"
               v-for="item in payments"
               :key="item.id"
               @click="setPayment(item['id'])"
               :class="{'checkout__radio--checked': item.checked}"
          >
            <input type="radio" :id="'payment_' + item['id']" class="checkout__radioInput"
                   :checked="item.checked"
            >
            <span class="checkout__radioBefore"></span>

            <label :for="'payment_' + item['id']" class="checkout__radioLabel" v-html="item.name"></label>
          </div>
        </div>
      </div>

      <div class="form__group w-100">
        <label for="address_point" class="form__label">Введите адрес пункта самовывоза для его выделения</label>
        <input type="text" id="address_point" placeholder="Адрес"
               class="form__input w-100"
               @input="enterAddress($event)"
               @change="changeAddress($event)">
      </div>

      <template v-if="orderReady">
        <Map
            :key="mapKey"
            :order_id="order.id"
            @select="selectedPoint"
            :pointId="pointId"
            :pointIds="pointIds"
            :providerName="providerName"
        />
      </template>


      <p class="checkout__title">Данные получателя:</p>

      <div class="form__section">
        <div class="form__group">
          <label for="receiver" class="form__label">Имя</label>
          <input type="text" class="form__input form__control" placeholder="Имя" id="receiver" :value="order.receiver"
                 @change="orderUpdate('receiver', $event.target.value)"
                 :class="{
                 'form__input--invalid' : errors.includes('receiver')
               }"
          >
        </div>

        <div class="form__group">
          <label for="lastname" class="form__label">Фамилия</label>
          <input type="text" class="form__input form__control" placeholder="Фамилия" id="lastname"
                 :value="order.lastname"
                 @change="orderUpdate('lastname', $event.target.value)"
                 :class="{
                 'form__input--invalid' :  errors.includes('lastname')
               }"
          >
        </div>
      </div>

      <div class="form__section">
        <div class="form__group">
          <label for="phone" class="form__label">Номер телефона</label>
          <vue-tel-input
              :defaultCountry="'ru'"
              :mode="'international'"
              :validCharactersOnly="true"
              v-model="telInputValue"
              :dropdownOptions="telDropdownOptions"
              :inputOptions="telInputOptions"
              :styleClasses="telInputStyleClasses"
              @validate="telInputValidate"
              @input="telInputMask"
          />
        </div>

        <div class="form__group">
          <label for="email" class="form__label">Email</label>
          <input type="email" class="form__input form__control" placeholder="Email" id="email" :value="order.email"
                 @change="orderUpdate('email', $event.target.value)"
                 :class="{
                 'form__input--invalid' : errors.includes('email')
               }"
          >
        </div>
      </div>

      <div class="form__group w-100">
        <label for="comment" class="form__label">Комментарий к заказу</label>
        <textarea id="comment" class="form__textarea w-100" v-html="order.comment"
                  @change="orderUpdate('comment', $event.target.value)"
        ></textarea>
      </div>

      <button class="button button--red"
              @click="submit"
      >Сделать заказ
      </button>
    </div>

    <div class="orderPage__bottomRight">
      <div class="orderPage__points">
        <template v-if="selectedPoints.length > 0">
          <template v-if="false">
            <select
                v-if="Object.keys(services).length  > 1"
                class="form-control mb-2"
                @change="changeProvider"
            >
              <option value="0">Все службы доставки</option>
              <option
                  v-for="service in services"
                  :key="service"
                  :value="service"
              >{{ service }}
              </option>
            </select>
          </template>

          <div class="orderPage__point"
               v-for="point in selectedPoints"
               :key="point.id"
               @click="setPoint(point)"
               :class="{'delivery__point--active': point.active}"
               :data-id="point.id"
          >
            <p><strong>{{ point.name }}, {{ point.street }}, {{ point.streetType }}</strong></p>
            <p>{{ point.address }}</p>
          </div>
        </template>
        <p v-else
           class="mt-0 mt-sm-4"
        >Пунктов самовывоза не найдено</p>
      </div>
    </div>
  </div>
</template>

<script>
import {VueTelInput} from 'vue-tel-input'
import request from "@/request";
import Map from "@/components/DeliveryPoint/Map";

export default {
  name: "dtl-Pickpoint",
  components: {
    VueTelInput,
    Map
  },
  created() {
    if (this.$parent.order_id !== undefined) {
      this.order_id = parseInt(this.$parent.order_id)
      this.getOrder()
      this.getDeliveryPoints()
    }
  },
  data() {
    return {
      loading: false,
      order: {},
      orderReady: false,
      payments: [],
      errors: [],
      phone: '',
      payment: 0,
      telInputValue: '',
      telInputStyleClasses: ['form__control'],
      telDropdownOptions: {
        showDialCodeInSelection: false,
        showFlags: true,
        showDialCodeInList: true,
        disabled: true
      },
      telInputOptions: {
        placeholder: '+7-___-___-__-__',
        required: true,
        name: 'phone'
      },
      mapKey: 0,
      pointId: 0,
      pointIds: [],
      services: {},
      providerName: '',
      providerAddress: '',
      selectedPoints: [],
    }
  },
  methods: {
    telInputValidate(input) {
      this.disabled = !input.valid
      this.telInputStyleClasses = ['form__control']
      if (input.valid === true) {
        this.phone = input.number
        this.telInputStyleClasses.push('is-valid')
        this.orderUpdate('phone', this.phone)
      }
    },
    telInputMask(value) {
      if (value === '87') {
        this.telInputValue = '+77'
      }
      if (value === '89') {
        this.telInputValue = '+79'
      }
      if (value === '77') {
        this.telInputValue = '+77'
      }
      if (value === '79') {
        this.telInputValue = '+79'
      }
    },
    setPayment(id) {
      this.payment = id
      this.payments.forEach(item => item.checked = item.id === id)
      this.orderUpdate('payment', id)
    },
    async orderUpdate(key, value) {
      const errors = []
      this.errors.forEach(item => {
        if (item !== key) {
          errors.push(item)
        }
      })
      this.errors = errors
      const formData = new FormData();
      if (this.order_id !== '') {
        formData.append('order_id', this.order_id)
        formData.append(key, value)
      }
      const response = await request.post('order/update', formData)
      if (response.data.success === true) {
        const formData = new FormData();
        if (this.order_id !== '') {
          formData.append('order_id', this.order_id)
        }
        const response = await request.get('order/get', formData)
        if (response.data.success === true) {
          this.order = response.data.order
        }
      }
      if (response.data.success === false) {
        this.order[key] = ''
      }
    },
    async submit() {
      this.loading = true
      this.errors = []
      const formData = new FormData();
      if (this.order_id !== '') {
        formData.append('order_id', this.order.id)
      }
      const response = await request.post('order/submit', formData)
      if (response.data.success === false) {
        this.loading = false
        if (response.data.message !== undefined && response.data.message !== '') {
          window.miniShop2.Message.error(response.data.message)
        }

        response.data.data.forEach((error) => {
          switch(error) {
            case  'payment':
              window.miniShop2.Message.error('Выберите способ оплаты')
              break
            case  'delivery_point':
              window.miniShop2.Message.error('Выберите пункт самовывоза')
              break
            default:
              this.errors.push(error)
          }
        })
      }
      if (response.data.success === true) {
        this.loading = false
        this.setEcommerce(response.data)
        const crhosts = ['devcr.dtlshop.ru', 'chemicalrussian.ru'];
        if (!crhosts.includes(location.host)) {
          window.ym(44716432, 'reachGoal', 'placed_an_order')
        }

        if (response.data.redirect !== undefined) {
          window.location.href = response.data.redirect
        }
      }
    },
    async getOrder() {
      this.loading = true
      const formData = new FormData();
      if (this.order_id !== '') {
        formData.append('order_id', this.order_id)
      }
      const response = await request.get('order/get', formData)
      if (response.data.success === true) {
        this.setOrderData(response.data)
        this.loading = false
      }
    },
    setOrderData(data) {
      this.orderReady = true
      this.order = data.order
      this.payments = data.payments

      this.telInputValue = data.order.phone
      this.setPayment(3)
      this.orderUpdate('delivery_provider', 'dtl');
    },
    async getDeliveryPoints() {
      const formData = new FormData();
      if (this.order_id !== '') {
        formData.append('order_id', this.order_id)
      }
      const response = await request.get('order/get-delivery-points', formData)

      if (response.data.success === true) {
        this.points = response.data.points
        this.selectedPoints = response.data.points

        if (response.data.points.length > 0) {
          response.data.points.forEach(point => {
            this.services[point.providerKey] = point.name
          })
        }
      }
    },
    selectedPoint(value) {
      this.points.forEach(item => {
        item.active = value.id === item.id;
      })

      const $el = document.querySelector(`[data-id="${value.id}"]`);
      if ($el) {
        $el.scrollIntoView();
      }
      this.orderUpdate('delivery_point', value.name)
    },
    enterAddress(event) {
      const value = event.target.value.trim();
      if (value.length > 2) {
        this.providerAddress = value
        this.reloadProviderList()
      }
      if (value.length === 0) {
        this.providerAddress = ''
        this.reloadProviderList()
      }
    },
    changeAddress(event) {
      const value = event.target.value.trim();
      if (value.length > 2) {
        this.providerAddress = value
        this.reloadMap()
        this.reloadProviderList()
      }
      if (value.length === 0) {
        this.providerAddress = ''
        this.reloadMap()
        this.reloadProviderList()
      }
    },
    changeProvider(event) {
      const value = event.target.value
      if (parseInt(value) !== 0) {
        this.providerName = value
      } else {
        this.providerName = ''
      }
      this.reloadMap()
      this.reloadProviderList()
    },
    reloadMap() {
      if (this.providerAddress.length > 2) {
        this.pointIds = ['-1']
        this.points.forEach(point => {
          if (point.address.indexOf(this.providerAddress) > -1) {
            this.pointIds.push(point.id)
          }
        })
      }
      if (this.providerAddress.length === 0) {
        this.pointIds = []
      }
      this.mapKey += 1
    },
    reloadProviderList() {
      this.selectedPoints = this.points.filter(point => {
        if (point.address.indexOf(this.providerAddress) === -1) {
          return false
        }

        return !(this.providerName !== '' && point.name !== this.providerName)
      })
    },
    setPoint(point) {
      if (point.active === true) {
        this.uncheckPoint()
      } else {
        this.checkPoint(point)
      }
    },
    checkPoint(point) {
      this.points.forEach(item => {
        item.active = point.id === item.id
      })
      const point_name = point.name + ', ' + point.address
      this.orderUpdate('delivery_point', point_name)
      this.pointId = point.id
    },
    uncheckPoint() {
      this.points.forEach(item => {
        item.active = false
      })
      this.orderUpdate('delivery_point', '')
      this.pointId = 0
    },
    setEcommerce(response) {
      const   dataLayerProducts = [];
      const r46Products = [];
      response.products.forEach(product => {
        dataLayerProducts.push({
          id: `${product.properties.article}`,
          price: product.properties.price,
          quantity: product.properties.count,
          name: `${product.properties.name}`,
          brand: `${product.properties.brand}`,
          category: `${product.properties.category}`
        });

        const crhosts = ['devcr.dtlshop.ru', 'chemicalrussian.ru'];
        if (!crhosts.includes(location.host)) {
          r46Products.push({
            id: `${product.properties.article}`,
            price: product.properties.price,
            amount: product.properties.count
          });
        }


      });

      window.dataLayer.push({
        'ecommerce': {
          'currencyCode': 'RUB',
          'purchase': {
            'actionField': {
              'id': `${response.order.num}`,
              'affiliation': `${location.host}`,
              'revenue': response.order.cost,
              'tax': '0',
              'shipping': '0',
            },
            'products': dataLayerProducts
          }
        },
        'event': 'ee_event',
        'ee_event_category': 'Enhanced Ecommerce',
        'ee_event_action': 'Purchase',
        'ee_event_non-interaction': 'False',
      });

      const crhosts = ['devcr.dtlshop.ru', 'chemicalrussian.ru'];
      if (!crhosts.includes(location.host)) {
        window.r46('track', 'purchase', {
          products: r46Products,
          order: `${response.order.num}`,
          order_price: response.order.cost
        });
      }

    },
  }
}
</script>