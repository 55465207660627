<script>
import { VueTelInput } from 'vue-tel-input'
import request from '@/request'
import Products from './Products.vue'

export default {
  name: 'vue-analog',
  props: ['productId', 'article', 'title'],
  watch: {
    productId () {
      if (this.productId !== null) {
        this.product_id = this.productId
        this.product_title = this.title
        this.product_article = this.article
        this.$emit('reset')
        window.MicroModal.show('modal-analog')
      }
    },
  },
  components: {
    VueTelInput,
    Products
  },
  data () {
    return {
      product_id: null,
      product_article: null,
      product_title: null,
      phone: '',
      telInputValue: '',
      telInputStyleClasses: ['form__input'],
      telDropdownOptions: {
        showDialCodeInSelection: false,
        showFlags: false,
        showDialCodeInList: false,
        showSearchBox: false,
        disabled: true
      },
      telInputOptions: {
        placeholder: '+7-___-___-__-__',
        required: true,
        name: 'phone'
      },
      phoneValidationFeedBack: null,
      disabled: true,
      loading: false,
      errors: {
        name: '',
        phone: '',
        email: ''
      },
      name: '',
      email: ''
    }
  },
  methods: {
    telInputValidate (input) {
      this.disabled = !input.valid
      this.telInputStyleClasses = ['form__input']
      if (input.valid === true) {
        this.phone = input.number
        this.telInputStyleClasses.push('form__input--valid')
      }
    },
    telInputMask (value) {
      if (value === '87') {
        this.telInputValue = '+77'
      }
      if (value === '89') {
        this.telInputValue = '+79'
      }
      if (value === '77') {
        this.telInputValue = '+77'
      }
      if (value === '79') {
        this.telInputValue = '+79'
      }
    },
    async send () {
      this.loading = true
      this.disabled = true
      this.phoneValidationFeedBack = null
      this.telInputStyleClasses = ['form__input']
      this.telInputStyleClasses = ['form__input']
      this.errors = {
        name: '',
        phone: '',
        email: ''
      }

      const formdata = new FormData()
      formdata.append('product_id', this.product_id)
      formdata.append('phone', this.phone)
      formdata.append('name', this.name)
      formdata.append('email', this.email)
      const response = await request.post('product/request-analog', formdata)

      if (response.data.success === false) {
        this.loading = false
        this.disabled = false

        if (response.data.message !== '') {
          window.miniShop2.Message.error(response.data.message)
        }

        if (response.data.errors !== undefined && Object.keys(response.data.errors).length > 0) {
          for (let key in response.data.errors) {
            this.errors[key] = response.data.errors[key]
          }
        }
      }

      if (response.data.success === true) {
        this.loading = false
        this.disabled = false

        window.MicroModal.close('modal-analog')
        window.miniShop2.Message.success('Заявка отправлена')
        // window.ym(44716432, 'reachGoal', 'Call_shapka')
      }
    },
  },
}
</script>

<template>
  <div class="analog">
    <div class="modal__container analog__container">
      <button class="modal__close" aria-label="Close modal" data-micromodal-close>
        <svg style="height: 16px; width: 16px;">
          <use xlink:href="assets/template2/images/symbol/sprite.svg#close_black"></use>
        </svg>
      </button>

      <header class="modal__header analog__header">
        <h2 class="modal__title" id="modal-callback-title">
          Подобрать аналог
        </h2>
      </header>

      <div class="modal__content analog__content" id="modal-callback-content">
        <template v-if="this.product_id !== null">
          <products
              :article="product_article"
              :title="product_title"
          />
        </template>
        <h3>Нужна консультация менеджера по подбору аналога?</h3>
        <p class="modal__text">Оставьте ваши контактные данные</p>

        <ul class="analog__intro-list">
          <li class="analog__intro-item"><i class="fa-solid fa-phone"></i> Позвоним</li>
          <li class="analog__intro-item"><i class="fa-solid fa-person-chalkboard"></i> Проконсультируем</li>
          <li class="analog__intro-item"><i class="fa-solid fa-check"></i> Поможем выбрать</li>
        </ul>
        <form class="modal__form">
          <div class="form__group">
            <input type="text" v-model="name" class="modal__input" placeholder="Ваше Имя"
                   :class="{'form__input--invalid' : errors.name !== ''}"
            >
          </div>

          <div class="form__group">
            <vue-tel-input
                :defaultCountry="'ru'"
                :mode="'international'"
                :validCharactersOnly="true"
                v-model="telInputValue"
                :dropdownOptions="telDropdownOptions"
                :inputOptions="telInputOptions"
                :styleClasses="telInputStyleClasses"
                @validate="telInputValidate"
                @input="telInputMask"
            />
            <div
                v-if="phoneValidationFeedBack !== null"
                class="invalid-feedback">
              {{ phoneValidationFeedBack }}
            </div>
          </div>

          <div class="form__group">
            <input type="text" v-model="email" class="modal__input" placeholder="Email"
                   :class="{'form__input--invalid' : errors.email !== ''}"
            >
          </div>
        </form>
      </div>

      <div class="modal__footer analog__footer">

        <button class="button button--red modal__button"
                style="padding: 15px;"
                :disabled="disabled"
                @click="send"
        > Отправить заявку
        </button>
      </div>
    </div>
    <div class="loader" v-if="loading">
      <span class="loader__indicator"></span>
    </div>
  </div>
</template>