import { render, staticRenderFns } from "./ProfilePhoto.vue?vue&type=template&id=421568ff&"
import script from "./ProfilePhoto.vue?vue&type=script&lang=js&"
export * from "./ProfilePhoto.vue?vue&type=script&lang=js&"
import style0 from "./ProfilePhoto.vue?vue&type=style&index=0&id=421568ff&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports