<template>
  <div>
    <div class="loader" v-if="loading">
      <span class="loader__indicator"></span>
    </div>
    <form class="profilePage__form" v-if="userReady">

      <div class="officePage__card">
        <ProfilePhoto
            :user_id="user_id"
            :photo="user.photo"
            @select="profilePhotoSelected($event)"
            @uploaded="uploaded"
            :startUpload="startUpload"
        />
        <p class="officePage__cardName">{{ user.lastname }} {{ user.fullname }} {{ user.middlename }}</p>
      </div>

      <div class="form__section">
        <div class="form__group">
          <label for="fullname" class="form__label">Фамилия</label>
          <input type="text" placeholder="Фамилия" id="fullname" class="form__input form__control"
                 v-model="user.lastname">
        </div>

        <div class="form__group">
          <label for="lastname" class="form__label">Имя</label>
          <input type="text" placeholder="Имя" id="lastname" class="form__input form__control" v-model="user.fullname">
        </div>

        <div class="form__group">
          <label for="middlename" class="form__label">Отчество</label>
          <input type="text" placeholder="Отчество" id="middlename" class="form__input form__control"
                 v-model="user.middlename">
        </div>
      </div>


      <div class="form__section">
        <div class="form__group">
          <label for="birthday" class="form__label">Дата рождения</label>
          <input type="text" placeholder="11.11.2001" id="birthday" class="form__input form__control" v-model="user.dob">
        </div>

        <div class="form__group">
          <span class="form__label">Пол</span>

          <div class="form__checkboxGroup">
            <div class="form__check">
              <input type="checkbox" id="male"  class="form__checkInput" :checked="parseInt(user.gender) === 1"
                     @change="user.gender = 1"
              >
              <label for="male" class="form__checkLabel">
                Мужской
              </label>
            </div>

            <div class="form__check">
              <input type="checkbox" id="female" class="form__checkInput" :checked="parseInt(user.gender) === 2"
                     @change="user.gender = 2" >
              <label for="female" class="form__checkLabel">
                Женский
              </label>
            </div>

          </div>
        </div>
      </div>

      <div class="form__section">
        <div class="form__group">
          <label for="country" class="form__label">Страна</label>
          <input type="text" placeholder="Страна" id="country" class="form__input form__control" v-model="user.country">
        </div>
          <template v-if="userReady">
              <dadata
                    :city="user.city"
                    :label="'город'"
                    @changeCity="changeCity"
              />
          </template>

        <div class="form__group">
          <label for="phone" class="form__label">Номер телефона</label>
          <template v-if="user.phone === ''">
            <vue-tel-input
                :defaultCountry="'ru'"
                :mode="'international'"
                :validCharactersOnly="true"
                v-model="telInputValue"
                :dropdownOptions="telDropdownOptions"
                :inputOptions="telInputOptions"
                :styleClasses="telInputStyleClasses"
                @validate="telInputValidate"
                @input="telInputMask"
            />
            <div class="alert alert-alert mt-4"
                 v-if="phoneValidationFeedBack !== null"
            >
              <p><strong> {{ phoneValidationFeedBack }}</strong></p>
            </div>
          </template>
          <template v-else>
            <input type="tel" id="phone" class="form__input form__control" v-model="user.phone" disabled>

            <div class="alert alert-warning mt-4">
              <p><strong>Изменение телефона доступно только через менеджера</strong></p>
            </div>
          </template>
        </div>
      </div>


      <button type="button" class="button button--yellow"
        @click="submit"
      >Сохранить изменения</button>
    </form>
  </div>
</template>

<script>
import request from "@/request";
import ProfilePhoto from "./ProfilePhoto";
import dadata from '../../Widgets/dadata.vue'
import {VueTelInput} from 'vue-tel-input'

export default {
  name: "ProfileForm",
  components: {
    dadata,
    ProfilePhoto,
    VueTelInput,
  },
  created() {
    if (this.$parent.user_id !== undefined) {
      this.user_id = parseInt(this.$parent.user_id)
      this.getUser()
    }
  },
  data() {
    return {
      loading: false,
      user: {},
      userReady: false,
      startUpload: false,
      fileRecordsForUpload: [],
      phone: '',
      telInputValue: '',
      telInputStyleClasses: ['form__input'],
      telDropdownOptions: {
        showDialCodeInSelection: false,
        showFlags: false,
        showDialCodeInList: false,
        showSearchBox: false,
        disabled: true
      },
      telInputOptions: {
        placeholder: '+7-___-___-__-__',
        required: true,
        name: 'phone'
      },
      phoneValidationFeedBack: null,
    }
  },
  methods: {
    telInputValidate(input) {
      this.phone = ''
      this.disabled = !input.valid
      this.telInputStyleClasses = ['form__input']
      if (input.valid === true) {
        this.phone = input.number
        this.telInputStyleClasses.push('form__input--valid')
      }
    },
    telInputMask(value) {
      if (value === '87') {
        this.telInputValue = '+77'
      }
      if (value === '89') {
        this.telInputValue = '+79'
      }
      if (value === '77') {
        this.telInputValue = '+77'
      }
      if (value === '79') {
        this.telInputValue = '+79'
      }
    },
    async getUser() {
      this.loading = true

      const formData = new FormData();
      if (this.user_id !== '') {
        formData.append('user_id', this.user_id)
      }
      const response = await request.get('office/profile', formData)
      if (response.data.success === true) {
        this.loading = false
        this.user = response.data.profile
        this.telInputValue = response.data.profile.phone
        this.userReady = true
      }
      if (response.data.success === false) {
        this.loading = false
      }
    },
    changeCity(value) {
      this.user.city = value
    },
    async submit() {
      if (this.fileRecordsForUpload.length > 0) {
        this.startUpload = true
      } else {
        await this.saveData()
      }
    },
    async saveData() {
      this.loading = true
      this.phoneValidationFeedBack = null

      const formData = new FormData();
      formData.append('user', JSON.stringify(this.user))
      if (this.phone !== '') {
        formData.append('new_phone', this.phone)
      }

      const response = await request.post('office/profile/' + this.user_id, formData)
      if (response.data.success === true) {
        this.loading = false
        window.miniShop2.Message.success('Сохранено')

        if (response.data.data.phone !== undefined) {
          if (response.data.data.phone.success === false) {
            this.phoneValidationFeedBack = response.data.data.phone.message
          }
        }
      }
      if (response.data.success === false) {
        this.loading = false
      }
    },
    profilePhotoSelected(images) {
      this.fileRecordsForUpload = images
    },
    uploaded() {
      this.startUpload = false
      this.fileRecordsForUpload = []
      this.saveData()
    }
  }
}
</script>